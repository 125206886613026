import React from "react";
import HOC from "../../components/HOC";
import InvoiceDetail from "../../components/screen/billing/InvoiceDetail";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const DetailBillingInvoicePage = () => {
  return (
    <div className="">
      <PageTemplate header="Detail Pesanan" mainComponent={<InvoiceDetail />} />
    </div>
  );
};

export default HOC(DetailBillingInvoicePage);
