import React from "react";
import HOC from "../../components/HOC";
import SettingInvoice from "../../components/screen/invoice/SettingInvoice";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const SettingInvoicePage = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header={"Setting Invoice " + (isWarehouse ? "Central Warehouse" : "")}
        mainComponent={<SettingInvoice isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 44 : 13}
      />
    </div>
  );
};

export default HOC(SettingInvoicePage);
