import React from "react";
import CreateOperationalCost from "../../../components/screen/Reporting/OperationalCost/CreateOperationalCost";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";

const CreateOperationalCostPage = () => {
  return (
    <div className="">
      {/* empty header due to dynamic header within component */}
      <PageTemplate
        header=""
        mainComponent={<CreateOperationalCost />}
        pageRole={5}
      />
    </div>
  );
};

export default CreateOperationalCostPage;
