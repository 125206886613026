import React from "react";
import ProductOutOfStock from "../../../components/screen/inventory/productHabis/ProductHabis";
import HOC from "../../../components/HOC";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";

const ProductHabisPage = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header={"Inventory " + (isWarehouse ? "Central Warehouse" : "")}
        mainComponent={<ProductOutOfStock isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 27 : 4}
      />
    </div>
  );
};

export default HOC(ProductHabisPage);
