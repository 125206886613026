import InitialAnalysisList from "../../../components/screen/patientCare/patientCareInitialAnalysis/InitialAnalysisList";
import HOC from "../../../components/HOC";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";

const InitialAnalysisListPage = () => {
  return (
    <PageTemplate
      header="Initial Analysis"
      mainComponent={<InitialAnalysisList />}
      pageRole={18}
    />
  );
};

export default HOC(InitialAnalysisListPage);
