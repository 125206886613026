import React from "react";
import HOC from "../../components/HOC";
import CreatePromo from "../../components/screen/invoice/CreatePromo";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const CreatePromoInvoicePage = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header={"Create Promo " + (isWarehouse ? "Central Warehouse" : "")}
        mainComponent={<CreatePromo isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 43 : 12}
      />
    </div>
  );
};

export default HOC(CreatePromoInvoicePage);
