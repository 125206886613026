import React from "react";
import HOC from "../../../components/HOC";
import AddReturWarehouse from "../../../components/screen/centralWarehouse/returWarehouse/AddReturWarehouse";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";

const AddReturnWarehousePage = () => {
  return (
    <div className="">
      <PageTemplate
        header="New Retur Warehouse"
        mainComponent={<AddReturWarehouse />}
        pageRole={36}
      />
    </div>
  );
};

export default HOC(AddReturnWarehousePage);
