import React from "react";
import DetailsProducts from "../../../components/screen/inventory/productActive/DetailsProducts";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import HOC from "../../../components/HOC";

const DetailsProductActive = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header="Detail Inventory"
        mainComponent={<DetailsProducts isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 27 : 4}
      />
    </div>
  );
};

export default HOC(DetailsProductActive);
