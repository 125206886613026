import React from "react";
import ReportingProfitnLoss from "../../../components/screen/Reporting/ProfitnLoss/ReportingProfitnLoss";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import HOC from "../../../components/HOC";

const ReportingProfitnLossPage = () => {
  return (
    <div className="">
      <PageTemplate
        header="Profit and Loss"
        mainComponent={<ReportingProfitnLoss />}
        pageRole={5}
      />
    </div>
  );
};

export default HOC(ReportingProfitnLossPage);
