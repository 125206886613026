import React from "react";
import DetailPurchaseOrder from "../../components/screen/purchaseOrder/DetailPurchaseOrder";
import HOC from "../../components/HOC";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const DetailPurchaseOrderPage = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header={"PO " + (isWarehouse ? "Central Warehouse" : "") + " Detail"}
        mainComponent={<DetailPurchaseOrder isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 29 : 9}
      />
    </div>
  );
};

export default HOC(DetailPurchaseOrderPage);
