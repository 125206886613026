import React from "react";
import OperationalCost from "../../../components/screen/Reporting/OperationalCost/OperationalCost";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import HOC from "../../../components/HOC";

const OperationalCostPage = () => {
  return (
    <div className="">
      <PageTemplate
        header="Operational Cost"
        mainComponent={<OperationalCost />}
        pageRole={5}
      />
    </div>
  );
};

export default HOC(OperationalCostPage);
