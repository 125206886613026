import React from "react";
import StockOpname from "../../../components/screen/inventory/StockOpname/StockOpname";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import HOC from "../../../components/HOC";

const StockOpnamePage = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header={"Stock Opname " + (isWarehouse ? "Central Warehouse" : "")}
        mainComponent={<StockOpname isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 33 : 23}
      />
    </div>
  );
};

export default HOC(StockOpnamePage);
