import HOC from "../../../components/HOC";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import DoctorAnalysisList from "../../../components/screen/patientCare/patientCareDoctorAnalysis/DoctorAnalysisList";

const DoctorAnalysisListPage = () => {
  return (
    <PageTemplate
      header="Doctor Analysis"
      mainComponent={<DoctorAnalysisList />}
      pageRole={19}
    />
  );
};

export default HOC(DoctorAnalysisListPage);
