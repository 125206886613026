import React from "react";
import HOC from "../../components/HOC";
import DetailBillingManual from "../../components/screen/billingManual/DetailBillingManual";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const DetailBillingManualPage = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header="Manual Billing"
        mainComponent={<DetailBillingManual isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 28 : 7}
      />
    </div>
  );
};

export default HOC(DetailBillingManualPage);
