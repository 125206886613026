import React from "react";
import HOC from "../../components/HOC";
import CreateCustomer from "../../components/screen/invoice/CreateCustomer";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const CreateCustomerInvoicePage = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header={"Create Customer " + (isWarehouse ? "Central Warehouse" : "")}
        mainComponent={<CreateCustomer isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 41 : 10}
      />
    </div>
  );
};

export default HOC(CreateCustomerInvoicePage);
