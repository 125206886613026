import React from "react";
import UpdateStock from "../../../components/screen/inventory/productActive/UpdateStock";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import HOC from "../../../components/HOC";

const UpdateStockActive = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header="Update Stock dan Harga"
        mainComponent={<UpdateStock isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 27 : 4}
      />
    </div>
  );
};

export default HOC(UpdateStockActive);
