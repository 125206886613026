import HOC from "../../../components/HOC";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import CopyPrescription from "../../../components/screen/patientCare/prescriptionHistory/CopyPrescription";

const MakeCopyPrescriptionPage = () => {
  return (
    <PageTemplate
      header="Make Prescription Copy"
      mainComponent={<CopyPrescription />}
      pageRole={26}
    />
  );
};

export default HOC(MakeCopyPrescriptionPage);
