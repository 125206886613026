import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Autocomplete,
  CircularProgress,
  Switch,
} from "@mui/material";
import {
  Clear as ClearIcon,
  CameraAlt as CameraAltIcon,
} from "@mui/icons-material";
import { BsImages } from "react-icons/bs";
import "react-datepicker/dist/react-datepicker.css";
import {
  useQuery,
  useMutation,
  //    useQueryClient
} from "@tanstack/react-query";
import { useFormik } from "formik";

import {
  getDetailInventory,
  getGroups,
  updateProduct,
  getCategoryMaster,
  getPrincipal,
  uploadImage,
  //deleteImage,
} from "../../../../services/InventoryAPI";

import useDebounce from "../../../hooks/useDebounce";
import { EMPTY_IMG_SRC } from "../../../constants/constant";
import ErrorPopup from "../../../common/particles.jsx/ErrorPopup";

const FETCH_LIMIT = 10;
const EditDetailsProducts = ({ isWarehouse }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  // const queryClient = useQueryClient();
  const [offset, setOffset] = useState(1);
  const [principalValue, setPrincipalValue] = useState([]);
  const [openPrincipal, setOpenPrincipal] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [optionPrincipal, setOptionPrincipal] = useState([]);
  const [optionCategory, setOptionCategory] = useState([]);
  const [categoryValue, setCategoryValue] = useState([]);
  const [searchPrincipal, setSearchPrincipal] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const debouncePrincipal = useDebounce(searchPrincipal, 500);

  const debounceCategory = useDebounce(searchCategory, 500);

  const { data } = useQuery({
    queryKey: ["detail_product"],
    queryFn: () => getDetailInventory(id, isWarehouse ? isWarehouse : false),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const { data: categories } = useQuery({
    queryKey: ["category", data],
    queryFn: () => getCategoryMaster(FETCH_LIMIT, offset, searchCategory),
    enabled: !!data,
  });

  const { data: principals, isLoading: isPrincipalLoading } = useQuery({
    queryKey: ["principal", data, debouncePrincipal, openPrincipal],
    queryFn: () => getPrincipal(FETCH_LIMIT, offset, debouncePrincipal),
    enabled: !!data,
  });

  const { mutate } = useMutation({
    mutationFn: updateProduct,
    onSuccess: () =>
      navigate(
        `${
          isWarehouse ? "/central-warehouse" : ""
        }/inventory/produk-aktif/detail-product/${id}`
      ),
  });

  const { mutate: mutateUploadImage, isLoading: isLoadingUpload } = useMutation(
    {
      mutationFn: uploadImage,
      onSuccess: (data) => {
        formik.setFieldValue("product_image", [
          ...formik.values.product_image,
          data,
        ]);
      },
      onError: (err) =>
        setErrorMessage(err.message.id ? err.message.id : err.message),
    }
  );

  const formik = useFormik({
    initialValues: {
      warehouse: isWarehouse ? isWarehouse : false,
      product_id: parseInt(id, 10),
      nie_number: data && data !== undefined ? data.nie_number : "",
      principal_id: data && data !== undefined ? data.product_principal_id : "",
      category_id:
        data &&
        data !== undefined &&
        data?.category_id?.length > 1 &&
        data?.category_id[0] !== 0
          ? data.product_category_id
          : [],
      label_id: data && data !== undefined ? data.label_product : "",
      product_name: data && data !== undefined ? data.product_name : "",
      product_image: data && data !== undefined ? data.media : [],
      product_description: {
        indication:
          data && data !== undefined ? data.description.indication : "",
        composition:
          data && data !== undefined ? data.description.composition : "",
        dosage: data && data !== undefined ? data.description.dosage : "",
        usage: data && data !== undefined ? data.description.usage : "",
        contraindication:
          data && data !== undefined ? data.description.contraindication : "",
        side_effect:
          data && data !== undefined ? data.description.side_effect : "",
        storage_description:
          data && data !== undefined
            ? data.description.storage_description
            : "",
      },
      shelf: data && data.shelf !== undefined ? data.shelf : "",
      price: {
        sell_price: data && data !== undefined ? data.price : 0,
        pharmacy_net_price: data && data !== undefined ? data.hna : 0,
        platform_price: data && data !== undefined ? data.platform_price : [],
        sell_price_primer: data && data !== undefined ? data.price_primer : 0,
        sell_price_sekunder:
          data && data !== undefined ? data.price_sekunder : 0,
        sell_price_tersier: data && data !== undefined ? data.price_tersier : 0,
        price_grosir: data && data !== undefined ? data.price_grosir : [],
      },
      packaging:
        data && data !== undefined
          ? data.packing
          : {
              total_strip: 0,
              total_unit: 0,
              total_box: 0,
              type_box: "",
              type_strip: "",
              type_unit: "",
            },
      batch: data && data !== undefined ? data.batch_details : [],
      require_prescription:
        data && data !== undefined ? data.description.require_prescription : "",
      product_inventory_id:
        data && data !== undefined ? data.product_inventory_id : "",
      barcode: data && data !== undefined ? data.barcode : "",
      status: data && data !== undefined ? data.status : 0,
      reminder_stock: data && data !== undefined ? data.reminder_stock : 0,
      reminder_uom: data && data !== undefined ? data.reminder_uom : 0,
      status_reminder_stock:
        data && data !== undefined ? data.status_reminder_stock : false,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      mutate({
        ...values,
        product_image:
          values.product_image.length === 0
            ? [EMPTY_IMG_SRC]
            : values.product_image,
      });
    },
  });

  const { data: groups } = useQuery({
    queryKey: ["groups"],
    queryFn: () => getGroups(),
  });

  // const { mutate: mutateDeleteImage } = useMutation({
  //   mutationFn: deleteImage,
  //   onSuccess: () =>
  //     queryClient.invalidateQueries({ queryKey: ["detail_product"] }),
  // });

  const handleDeleteImage = useCallback(
    (index, value) => {
      formik.setFieldValue(
        "product_image",
        formik.values.product_image.filter((data, idx) => idx !== index)
      );
    },
    [formik.values.product_image]
  );

  useEffect(() => {
    if (openCategory || openPrincipal) {
      setOptionCategory(categories !== undefined ? categories : []);
      setOptionPrincipal(principals !== undefined ? principals : []);
    }
  }, [categories, principals, openPrincipal, openCategory]);

  useEffect(() => {
    setPrincipalValue({
      id: data && data !== undefined ? data.product_principal_id : "",
      name: data && data !== undefined ? data.product_principal_name : "",
    });
  }, [data]);

  useEffect(() => {
    setCategoryValue(
      data &&
        data !== null &&
        data?.category_id?.length > 1 &&
        data?.category_id[0] !== 0
        ? data.product_category_id.map((id, index) => ({
            id,
            name: data.product_category_name
              ? data.product_category_name[index]
              : "",
          }))
        : []
    );
  }, [debounceCategory, data]);

  return (
    <div className="container mx-auto w-full mt-16 lg:mt-0">
      {data && data !== undefined ? (
        <>
          <div className="flex text-blue-500">
            <p
              onClick={() =>
                navigate(
                  `${
                    isWarehouse ? "/central-warehouse" : ""
                  }/inventory/produk-aktif`
                )
              }
              className="hover:cursor-pointer hover:underline"
            >
              Produk Aktif
            </p>
            <p className="mx-2">/</p>
            <p
              onClick={() =>
                navigate(
                  `${
                    isWarehouse ? "/central-warehouse" : ""
                  }/inventory/produk-aktif/detail-product/${id}`
                )
              }
              className="hover:cursor-pointer hover:underline"
            >
              Detail Produk
            </p>
            <p className="mx-2">/</p>
            <p>Update Information</p>
          </div>
          <>
            <form onSubmit={formik.handleSubmit}>
              <div className="grid grid-col-1 shadow-md rounded-lg mt-5 p-3">
                <h1 className="font-medium text-[20px] lg:text-[25px]">
                  Detail Produk
                </h1>
                <TextField
                  id="outlined-basic"
                  label="Nomor Izin Edar"
                  value={formik.values.nie_number}
                  onChange={(e) =>
                    formik.setFieldValue("nie_number", e.target.value)
                  }
                  disabled
                  variant="outlined"
                  className="mt-3"
                />

                <div className="input-foto">
                  <div className="bg-blue-500 p-3 text-white font-medium flex mt-4 items-center gap-3 text-[20px] rounded-t-lg">
                    <span>
                      <BsImages />
                    </span>
                    Foto Produk
                  </div>
                  <div className="flex items-center justify-center h-64 border-2 border-dashed cursor-pointer">
                    {isLoadingUpload ? (
                      <CircularProgress size={20} className="my-4" />
                    ) : (
                      <>
                        {" "}
                        {(formik.values.product_image.length === 0
                          ? [EMPTY_IMG_SRC]
                          : formik.values.product_image
                        ).map((value, index) => (
                          <div
                            className="flex items-start justify-center w-[25%] mx-[10px]"
                            key={index}
                          >
                            <img
                              alt=""
                              className="rounded-md h-[50%] w-[50%]"
                              src={value}
                            />
                            {formik.values.product_image.length === 0 ? null : (
                              <button
                                type="button"
                                onClick={() => {
                                  handleDeleteImage(index, value);
                                }}
                              >
                                <ClearIcon />
                              </button>
                            )}
                          </div>
                        ))}
                        <label
                          htmlFor="dropzone-file1"
                          className="cursor-pointer flex flex-col items-center justify-center w-[15%] p-6 border-2 rounded-md"
                        >
                          <input
                            id="dropzone-file1"
                            type="file"
                            hidden
                            disabled={formik.values.product_image.length >= 3}
                            accept="accept='image/jpeg,image/png,image/jpg"
                            onChange={(e) => {
                              if (formik.values.product_image.length >= 3) {
                                return;
                              }
                              mutateUploadImage(e.target.files[0]);
                            }}
                          />
                          <div className="flex justify-center flex-col text-center">
                            <CameraAltIcon
                              fontSize="large"
                              sx={{ color: "black" }}
                            />
                            <p>
                              {formik.values.product_image.length === 0
                                ? 1
                                : formik.values.product_image.length}
                              /3
                            </p>
                          </div>
                        </label>
                      </>
                    )}
                  </div>
                </div>
                <TextField
                  id="outlined-basic"
                  label="Nama Obat"
                  name="product_name"
                  variant="outlined"
                  value={formik.values.product_name}
                  onChange={(e) =>
                    formik.setFieldValue("product_name", e.target.value)
                  }
                  className="mt-3"
                />
                <Autocomplete
                  fullWidth
                  multiple
                  filterSelectedOptions
                  sx={{ marginTop: 3 }}
                  open={openCategory}
                  onOpen={() => setOpenCategory(true)}
                  onClose={() => setOpenCategory(false)}
                  disablePortal
                  options={optionCategory}
                  value={categoryValue}
                  onChange={(_, inputValue) => {
                    setCategoryValue(inputValue);
                    formik.setFieldValue(
                      "category_id",
                      inputValue !== null ? inputValue.map((val) => val.id) : []
                    );
                    setOptionCategory([]);
                  }}
                  onInputChange={(_, onInputValue, reason) => {
                    if (reason === "input") {
                      setSearchCategory(onInputValue);
                      setOptionCategory([]);
                    }
                  }}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tambah Kategori"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                <TextField
                  id="outlined-basic"
                  name="indication"
                  value={formik.values.product_description.indication}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "product_description.indication",
                      e.target.value
                    )
                  }
                  label="Indikasi / Kegunaan"
                  variant="outlined"
                  className="mt-3"
                />
                <TextField
                  id="outlined-basic"
                  name="composition"
                  value={formik.values.product_description.composition}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "product_description.composition",
                      e.target.value
                    )
                  }
                  label="Kandungan / Komposisi"
                  variant="outlined"
                  className="mt-3"
                />
                <TextField
                  id="outlined-multiline-static"
                  name="dosage"
                  value={formik.values.product_description.dosage}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "product_description.dosage",
                      e.target.value
                    )
                  }
                  label="Dosis"
                  multiline
                  rows={3}
                  className="mt-3"
                />
                <TextField
                  id="outlined-multiline-static"
                  name="usage"
                  label="Cara Pemakaian"
                  multiline
                  rows={2}
                  value={formik.values.product_description.usage}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "product_description.usage",
                      e.target.value
                    )
                  }
                  className="mt-3"
                />
                <TextField
                  id="outlined-basic"
                  name="contraindication"
                  label="Kontradiksi / Jangan digunakan oleh"
                  value={formik.values.product_description.contraindication}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "product_description.contraindication",
                      e.target.value
                    )
                  }
                  variant="outlined"
                  className="mt-3"
                />
                <TextField
                  id="outlined-basic"
                  label="Efek Samping"
                  name="side_effect"
                  multiline
                  rows={3}
                  variant="outlined"
                  className="mt-3"
                  value={formik.values.product_description.side_effect}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "product_description.side_effect",
                      e.target.value
                    )
                  }
                />
                <TextField
                  id="outlined-basic"
                  label="Cara Penyimpanan"
                  name="storage_description"
                  variant="outlined"
                  className="mt-3"
                  value={formik.values.product_description.storage_description}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "product_description.storage_description",
                      e.target.value
                    )
                  }
                />

                <FormControl fullWidth className="mt-3">
                  <InputLabel id="demo-simple-select-label">
                    Golongan Obat
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    name="label_id"
                    value={formik.values.label_id}
                    label="Golongan Obat"
                    onChange={(e) =>
                      formik.setFieldValue("label_id", e.target.value)
                    }
                  >
                    <MenuItem
                      sx={{ display: "none" }}
                      disabled
                      value=""
                    ></MenuItem>
                    {groups &&
                      groups.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <TextField
                  id="outlined-basic"
                  label="require-prescription"
                  variant="filled"
                  className="mt-3"
                  disabled
                  value={formik.values.require_prescription ? "Ya" : "Tidak"}
                  onChange={(e) =>
                    formik.setFieldValue("require_prescription", e.target.value)
                  }
                />
                <Autocomplete
                  fullWidth
                  sx={{ marginTop: 3 }}
                  loading={isPrincipalLoading}
                  open={openPrincipal}
                  onOpen={() => setOpenPrincipal(true)}
                  onClose={() => setOpenPrincipal(false)}
                  disablePortal
                  options={optionPrincipal}
                  value={principalValue}
                  onChange={(_, inputValue) => {
                    setPrincipalValue(inputValue);
                    formik.setFieldValue("principal_id", inputValue?.id);
                    setOptionPrincipal([]);
                  }}
                  onInputChange={(_, onInputValue, reason) => {
                    if (reason === "input") {
                      setSearchPrincipal(onInputValue);
                      setOptionPrincipal([]);
                    }
                  }}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                  id="combo-box-demo"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Principal"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isPrincipalLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                <TextField
                  id="outlined-basic"
                  label="Barcode"
                  className="mt-3"
                  value={formik.values.barcode}
                  onChange={(e) =>
                    formik.setFieldValue("barcode", e.target.value)
                  }
                />
                <TextField
                  id="outlined-basic"
                  label="Rak (optional)"
                  className="mt-3"
                  value={formik.values.shelf}
                  onChange={(e) =>
                    formik.setFieldValue("shelf", e.target.value)
                  }
                />
                <div className="flex justify-between items-center mt-4">
                  <p className="font-bold">Pengingat Produk Habis</p>
                  <Switch
                    checked={formik.values.status_reminder_stock}
                    onClick={() => {
                      formik.setFieldValue(
                        "status_reminder_stock",
                        !formik.values.status_reminder_stock
                      );
                    }}
                  />
                </div>
                {formik.values.status_reminder_stock ? (
                  <div className="flex flex-row gap-2 items-center mt-4">
                    <p>Nyalakan Pengingat jika stok mencapai</p>
                    <div>
                      <button
                        className="bg-[#FF7A5D] p-3 mr-[10px] rounded-lg disabled:bg-gray-500"
                        type="button"
                        onClick={() => {
                          if (formik.values.reminder_stock === 0) return;
                          formik.setFieldValue(
                            "reminder_stock",
                            formik.values.reminder_stock - 1
                          );
                        }}
                      >
                        -
                      </button>
                      <input
                        type="text"
                        className="border rounded-lg w-[69px] p-3"
                        value={formik.values.reminder_stock}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "reminder_stock",
                            Number(e.target.value)
                          )
                        }
                      />
                      <button
                        className="bg-[#5DFFBB] p-3 ml-[10px] rounded-lg disabled:bg-gray-500"
                        type="button"
                        onClick={() => {
                          formik.setFieldValue(
                            "reminder_stock",
                            formik.values.reminder_stock + 1
                          );
                        }}
                      >
                        +
                      </button>
                    </div>
                    <FormControl sx={{ marginLeft: 3, width: "50%" }}>
                      <InputLabel id="demo-simple-select-label">
                        Pilihan Package
                      </InputLabel>
                      <Select
                        label="Pilihan Package"
                        value={formik.values.reminder_uom}
                        disabled={formik.values.reminder_stock === false}
                        onChange={(e) =>
                          formik.setFieldValue("reminder_uom", e.target.value)
                        }
                      >
                        <MenuItem value={0}>Primer</MenuItem>
                        <MenuItem value={1}>Sekunder</MenuItem>
                        <MenuItem value={2}>Tersier</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                ) : null}

                <div className="grid grid-cols-2 mt-10 gap-12">
                  <button
                    className="bg-red-700 text-white font-semibold p-3 rounded-lg"
                    onClick={() =>
                      navigate(
                        `${
                          isWarehouse ? "/central-warehouse" : ""
                        }/inventory/produk-aktif/detail-product/${id}`
                      )
                    }
                  >
                    Batal
                  </button>
                  <button
                    className="bg-orange-400 text-white font-semibold p-3 rounded-lg disabled:bg-gray-300"
                    disabled={formik.values.category_id.length === 0}
                    type="submit"
                  >
                    Konfirmasi
                  </button>
                </div>
              </div>
            </form>
          </>
        </>
      ) : (
        <p>Tidak Ditemukan!</p>
      )}

      <ErrorPopup
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
    </div>
  );
};

export default EditDetailsProducts;
