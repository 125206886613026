import React from "react";
import GoodDoctor from "../../components/screen/sinkronisasi/GoodDoctor";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const SyncGoodDoctorPage = () => {
  return (
    <div className="">
      <PageTemplate
        header="Sinkronisasi Good Doctor"
        mainComponent={<GoodDoctor />}
        pageRole={45}
      />
    </div>
  );
};

export default SyncGoodDoctorPage;
