import React from "react";
import InventorySummaryDetail from "../../../components/screen/inventory/InventorySummary/InventorySummaryDetail";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import HOC from "../../../components/HOC";

const InventorySummaryDetailPage = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header={"Detail Stock " + (isWarehouse ? "Central Warehouse" : "")}
        mainComponent={<InventorySummaryDetail isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 31 : 25}
      />
    </div>
  );
};

export default HOC(InventorySummaryDetailPage);
