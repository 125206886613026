import React from "react";
import HOC from "../../components/HOC";
import {
  initialValues,
  validationSchema,
} from "../../components/context/CostumerProviderFormik";
import DetailSalesComponents from "../../components/screen/sales/DetailSales";
import { Formik } from "formik";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const DetailSales = () => {
  return (
    <Formik
      validateOnChange
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <div className="">
        <PageTemplate
          header="Detail Sales"
          mainComponent={<DetailSalesComponents />}
          pageRole={0}
        />
      </div>
    </Formik>
  );
};

export default HOC(DetailSales);
