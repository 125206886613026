import React from "react";
import HOC from "../../components/HOC";
import CustomerList from "../../components/screen/invoice/CustomerList";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const CustomerInvoicePage = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header={isWarehouse ? "Central Warehouse" : "" + " Customer List"}
        mainComponent={<CustomerList isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 41 : 10}
      />
    </div>
  );
};

export default HOC(CustomerInvoicePage);
