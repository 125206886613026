import React from "react";
import CreatePurchase from "../../../components/screen/inventory/productHabis/CreatePurchase";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import HOC from "../../../components/HOC";

const CreatePurchasePage = () => {
  return (
    <div className="">
      <PageTemplate
        header="Create Purchase"
        mainComponent={<CreatePurchase />}
        pageRole={4}
      />
    </div>
  );
};

export default HOC(CreatePurchasePage);
