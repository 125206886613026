import React from "react";
import CardStockNew from "../../../components/screen/inventory/CardStock/CardStockNew";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import HOC from "../../../components/HOC";

const CardStockNewPage = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header={"Kartu Stock " + (isWarehouse ? "Central Warehouse" : "")}
        mainComponent={<CardStockNew isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 30 : 24}
      />
    </div>
  );
};

export default HOC(CardStockNewPage);
