import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { BsFillFileEarmarkTextFill } from "react-icons/bs";
import Delivery from "../../../../assets/icons/delivery.png";

import { groupingProduct } from "../../../../services/InventoryAPI";
import AddProductStockoutContent from "../../../common/particles.jsx/AddProductStockoutContent";
import { HabisListContext } from "../../../context/ProductHabisProvider";

const CreatePurchase = () => {
  const navigate = useNavigate();
  const { setPrintSP } = useContext(HabisListContext);
  const [open, setOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState([]);
  const [dataBuffer, setDataBuffer] = useState([]);
  const [principalId, setPrincipalId] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [note, setNote] = useState("");

  const {
    mutate: mutateGroupingProduct,
    data: groupingProductData,
    isLoading: isGroupingProductLoading,
  } = useMutation({
    mutationFn: groupingProduct,
  });

  useEffect(() => {
    const id = sessionStorage
      .getItem("id")
      .split(",")
      .map((item) => {
        return parseInt(item, 10);
      });
    setSelectedProductId(id);
    mutateGroupingProduct({ product_id: id, tipe: 1 });
  }, [trigger]);

  useEffect(() => {
    setDataBuffer(
      groupingProductData && groupingProductData !== undefined
        ? {
            detail_product:
              groupingProductData.detail_product !== null
                ? groupingProductData.detail_product?.map((data) => ({
                    principal_id: data.principal_id,
                    principal_name: data.principal_name,
                    product_by_category: data.product_by_category?.map(
                      (item) => ({
                        golongan_id: item.golongan_id,
                        golongan_name: item.golongan_name,
                        products: item.products?.map((list) => ({
                          bentuk_sediaan: list.bentuk_sediaan,
                          expired_date: list.expired_date,
                          label_name: list.label_name,
                          primary_batch: list.primary_batch,
                          principal_name: list.principal_name,
                          product_id: list.product_id,
                          product_label_id: list.product_label_id,
                          product_name: list.product_name,
                          product_principal_id: list.product_principal_id,
                          quantity: 1,
                          satuan: list.satuan,
                        })),
                      })
                    ),
                  }))
                : [],
            product_purchase:
              groupingProductData.product_purchase !== null
                ? groupingProductData.product_purchase?.map((data) => ({
                    principal_id: data.principal_id,
                    principal_name: data.principal_name,
                    product_by_category: data.product_by_category?.map(
                      (item) => ({
                        golongan_id: item.golongan_id,
                        golongan_name: item.golongan_name,
                        products: item.products?.map((list) => ({
                          bentuk_sediaan: list.bentuk_sediaan,
                          expired_date: list.expired_date,
                          label_name: list.label_name,
                          primary_batch: list.primary_batch,
                          principal_name: list.principal_name,
                          product_id: list.product_id,
                          product_label_id: list.product_label_id,
                          product_name: list.product_name,
                          product_principal_id: list.product_principal_id,
                          quantity: 1,
                          satuan: list.satuan,
                        })),
                      })
                    ),
                  }))
                : [],
          }
        : []
    );
  }, [groupingProductData]);

  return (
    <div className="container mx-auto  mt-16 lg:mt-0">
      {isGroupingProductLoading ? (
        <div className="flex justify-center items-center">
          <CircularProgress />
        </div>
      ) : null}
      {groupingProductData && groupingProductData !== undefined ? (
        <>
          <div className="flex gap-3 text-blue-500">
            <p
              className="hover:cursor-pointer"
              onClick={() => navigate("/inventory/produk-habis")}
            >
              Produk Habis
            </p>
            <p>/</p>
            <p>Create Purchase</p>
          </div>
          <div className="overflow-auto flex flex-row gap-3 items-center p-3 shadow-md rounded-xl mt-5 border">
            <img src={Delivery} alt="" />
            <div className="flex flex-col">
              <h1 className="font-bold">Informasi Pengiriman</h1>
              <p>
                {groupingProductData.address.apotek_name} |{" "}
                {groupingProductData.address.no_telp}
              </p>
              <p>
                {/* Cakung, Kota Admnistrasi Jakarta Timur <br /> DKI Jakarta 13950 */}
                {groupingProductData.address.address}
              </p>
              <div className="flex flex-row shadow-md p-3 rounded-xl items-center gap-3 border mt-3">
                <BsFillFileEarmarkTextFill />
                <p>Catatan Pengiriman</p>
                <input
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  className="border"
                />
              </div>
            </div>
          </div>
          <div className="mt-5 ">
            <h1 className="text-center font-bold text-[20px]">List Cart</h1>
          </div>
          <>
            {dataBuffer && dataBuffer !== undefined ? (
              <>
                {dataBuffer.product_purchase?.map((data, index) => (
                  <React.Fragment key={index}>
                    <div className="flex flex-row justify-between mt-20 items-center">
                      <h1 className="my-3 font-semibold">
                        Prinsipal: {data.principal_name}
                      </h1>
                      <p
                        onClick={() => {
                          setOpen(true);
                          setPrincipalId(data.principal_id);
                        }}
                        className="text-blue-500 hover:underline cursor-pointer"
                      >
                        + Tambah Produk
                      </p>
                    </div>
                    {data.product_by_category?.map((product, idx) => (
                      <React.Fragment key={idx}>
                        <h1 className="my-3 font-semibold">
                          Golongan: {product.golongan_name}
                        </h1>
                        <TableContainer
                          component={Paper}
                          sx={{ width: "100%", marginBottom: 3 }}
                        >
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow className="font-semibold">
                                <TableCell align="center">Nama</TableCell>
                                <TableCell align="center">Tipe</TableCell>
                                <TableCell align="center">Kuantitas</TableCell>
                                <TableCell align="center">UOM</TableCell>
                                <TableCell align="center">
                                  Hapus Barang
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {product.products?.map((item, i) =>
                                product.products?.length === 0 ? null : (
                                  <TableRow
                                    key={item.product_id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      align="center"
                                    >
                                      {item.product_name}
                                    </TableCell>
                                    <TableCell align="center">
                                      {item.label_name}
                                    </TableCell>
                                    <TableCell align="center">
                                      <div className="flex flex-row gap-2 justify-center items-center">
                                        <button
                                          className="px-2 text-[20px] rounded-md shadow-md bg-red-500 text-white"
                                          type="button"
                                          onClick={() => {
                                            if (item.quantity === 1) return;
                                            setDataBuffer((prevValue) => ({
                                              ...prevValue,
                                              product_purchase:
                                                prevValue.product_purchase.map(
                                                  (a) => {
                                                    return {
                                                      ...a,
                                                      product_by_category:
                                                        a.product_by_category.map(
                                                          (b) => {
                                                            return {
                                                              ...b,
                                                              products:
                                                                b.products.map(
                                                                  (c) => {
                                                                    return c.product_id ===
                                                                      item.product_id
                                                                      ? {
                                                                          ...c,
                                                                          quantity:
                                                                            c.quantity -
                                                                            1,
                                                                        }
                                                                      : c;
                                                                  }
                                                                ),
                                                            };
                                                          }
                                                        ),
                                                    };
                                                  }
                                                ),
                                            }));
                                          }}
                                        >
                                          -
                                        </button>
                                        <input
                                          className="w-16 h-10 text-center rounded-md shadow-md bg-gray-100 text-black"
                                          value={
                                            item.quantity !== null
                                              ? item.quantity
                                              : 1
                                          }
                                          onChange={(e) => {
                                            setDataBuffer((prevValue) => ({
                                              ...prevValue,
                                              product_purchase:
                                                prevValue.product_purchase.map(
                                                  (a) => {
                                                    return {
                                                      ...a,
                                                      product_by_category:
                                                        a.product_by_category.map(
                                                          (b) => {
                                                            return {
                                                              ...b,
                                                              products:
                                                                b.products.map(
                                                                  (c) => {
                                                                    return c.product_id ===
                                                                      item.product_id
                                                                      ? {
                                                                          ...c,
                                                                          quantity:
                                                                            Number(
                                                                              e
                                                                                .target
                                                                                .value
                                                                            ),
                                                                        }
                                                                      : c;
                                                                  }
                                                                ),
                                                            };
                                                          }
                                                        ),
                                                    };
                                                  }
                                                ),
                                            }));
                                          }}
                                        />
                                        <button
                                          className="px-2 text-[20px] rounded-md shadow-md bg-green-500 text-white"
                                          type="button"
                                          onClick={() => {
                                            setDataBuffer((prevValue) => ({
                                              ...prevValue,
                                              product_purchase:
                                                prevValue.product_purchase.map(
                                                  (a) => {
                                                    return {
                                                      ...a,
                                                      product_by_category:
                                                        a.product_by_category.map(
                                                          (b) => {
                                                            return {
                                                              ...b,
                                                              products:
                                                                b.products.map(
                                                                  (c) => {
                                                                    return c.product_id ===
                                                                      item.product_id
                                                                      ? {
                                                                          ...c,
                                                                          quantity:
                                                                            c.quantity +
                                                                            1,
                                                                        }
                                                                      : c;
                                                                  }
                                                                ),
                                                            };
                                                          }
                                                        ),
                                                    };
                                                  }
                                                ),
                                            }));
                                          }}
                                        >
                                          +
                                        </button>
                                      </div>
                                    </TableCell>
                                    <TableCell align="center">
                                      {item.satuan}
                                    </TableCell>
                                    <TableCell align="center">
                                      <p
                                        className="font-semibold text-red-400 cursor-pointer hover:underline"
                                        onClick={() => {
                                          if (
                                            product.products?.length === 1 ||
                                            selectedProductId.length === 1
                                          ) {
                                            setDataBuffer((prevValue) => ({
                                              ...prevValue,
                                              product_purchase:
                                                prevValue.product_purchase.filter(
                                                  (list) =>
                                                    list.principal_id !==
                                                    data.principal_id
                                                ),
                                            }));
                                          } else {
                                            setDataBuffer((prevValue) => ({
                                              ...prevValue,
                                              product_purchase:
                                                prevValue.product_purchase.map(
                                                  (a) => {
                                                    return {
                                                      ...a,
                                                      product_by_category:
                                                        a.product_by_category.map(
                                                          (b) => {
                                                            return {
                                                              ...b,
                                                              products:
                                                                b.products.filter(
                                                                  (c) => {
                                                                    return (
                                                                      c.product_id !==
                                                                      item.product_id
                                                                    );
                                                                  }
                                                                ),
                                                            };
                                                          }
                                                        ),
                                                    };
                                                  }
                                                ),
                                            }));
                                          }
                                          setSelectedProductId(
                                            selectedProductId.filter(
                                              (s) => s !== item.product_id
                                            )
                                          );
                                          sessionStorage.setItem(
                                            "id",
                                            selectedProductId.filter(
                                              (s) => s !== item.product_id
                                            )
                                          );
                                        }}
                                      >
                                        Hapus
                                      </p>
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        {/* {data.label ===
                    "PT. Millenial Pharmacon International, Tbk" ? (
                      <div className="flex justify-center mt-3 ">
                        <button className="p-3 bg-orange-500 text-white rounded-lg shadow-md">
                          Checkout Via Aplikasi
                        </button>
                      </div>
                    ) : data.label !==
                      "PT. Millenial Pharmacon International, Tbk" ? ( */}
                        <div className="flex justify-center my-3">
                          <button
                            className="p-3 bg-white text-black rounded-lg border-2 w-full"
                            onClick={() => {
                              setPrintSP({
                                type:
                                  product.golongan_id === 4 ||
                                  product.golongan_id === 5
                                    ? product.golongan_id
                                    : 0,
                                nama_principal: data.principal_name,
                                product: product.products.map((p) => ({
                                  product_id: p.product_id,
                                  quantity:
                                    p.quantity !== null ? p.quantity : 1,
                                })),
                              });
                              navigate("/inventory/print-SP");
                            }}
                          >
                            Cetak SP
                          </button>
                        </div>
                        {/* ) : null} */}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </>
            ) : (
              <p>kosong!</p>
            )}
            {dataBuffer && dataBuffer !== undefined ? (
              <>
                {dataBuffer.detail_product?.map((data, index) => (
                  <React.Fragment key={index}>
                    <div className="flex flex-row justify-between mt-20 items-center">
                      <h1 className="my-3 font-semibold">
                        Prinsipal: {data.principal_name}
                      </h1>
                      <p
                        onClick={() => {
                          setOpen(true);
                          setPrincipalId(data.principal_id);
                        }}
                        className="text-blue-500 hover:underline cursor-pointer"
                      >
                        + Tambah Produk
                      </p>
                    </div>
                    {data.product_by_category?.map((product, idx) => (
                      <React.Fragment key={idx}>
                        <h1 className="my-3 font-semibold">
                          Golongan: {product.golongan_name}
                        </h1>
                        <TableContainer
                          component={Paper}
                          sx={{ width: "100%", marginBottom: 3 }}
                        >
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow className="font-semibold">
                                <TableCell align="center">Nama</TableCell>
                                <TableCell align="center">Tipe</TableCell>
                                <TableCell align="center">Kuantitas</TableCell>
                                <TableCell align="center">UOM</TableCell>
                                <TableCell align="center">
                                  Hapus Barang
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {product.products?.map((item, i) =>
                                product.products?.length === 0 ? null : (
                                  <TableRow
                                    key={item.product_id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      align="center"
                                    >
                                      {item.product_name}
                                    </TableCell>
                                    <TableCell align="center">
                                      {item.label_name}
                                    </TableCell>
                                    <TableCell align="center">
                                      <div className="flex flex-row gap-2 justify-center items-center">
                                        <button
                                          className="px-2 text-[20px] rounded-md shadow-md bg-red-500 text-white"
                                          type="button"
                                          onClick={() => {
                                            if (item.quantity === 1) return;
                                            setDataBuffer((prevValue) => ({
                                              ...prevValue,
                                              detail_product:
                                                prevValue.detail_product.map(
                                                  (a) => {
                                                    return {
                                                      ...a,
                                                      product_by_category:
                                                        a.product_by_category.map(
                                                          (b) => {
                                                            return {
                                                              ...b,
                                                              products:
                                                                b.products.map(
                                                                  (c) => {
                                                                    return c.product_id ===
                                                                      item.product_id
                                                                      ? {
                                                                          ...c,
                                                                          quantity:
                                                                            c.quantity -
                                                                            1,
                                                                        }
                                                                      : c;
                                                                  }
                                                                ),
                                                            };
                                                          }
                                                        ),
                                                    };
                                                  }
                                                ),
                                            }));
                                          }}
                                        >
                                          -
                                        </button>
                                        <input
                                          className="w-16 h-10 text-center rounded-md shadow-md bg-gray-100 text-black"
                                          value={
                                            item.quantity !== null
                                              ? item.quantity
                                              : 1
                                          }
                                          onChange={(e) => {
                                            setDataBuffer((prevValue) => ({
                                              ...prevValue,
                                              detail_product:
                                                prevValue.detail_product.map(
                                                  (a) => {
                                                    return {
                                                      ...a,
                                                      product_by_category:
                                                        a.product_by_category.map(
                                                          (b) => {
                                                            return {
                                                              ...b,
                                                              products:
                                                                b.products.map(
                                                                  (c) => {
                                                                    return c.product_id ===
                                                                      item.product_id
                                                                      ? {
                                                                          ...c,
                                                                          quantity:
                                                                            Number(
                                                                              e
                                                                                .target
                                                                                .value
                                                                            ),
                                                                        }
                                                                      : c;
                                                                  }
                                                                ),
                                                            };
                                                          }
                                                        ),
                                                    };
                                                  }
                                                ),
                                            }));
                                          }}
                                        />
                                        <button
                                          className="px-2 text-[20px] rounded-md shadow-md bg-green-500 text-white"
                                          type="button"
                                          onClick={() => {
                                            setDataBuffer((prevValue) => ({
                                              ...prevValue,
                                              detail_product:
                                                prevValue.detail_product.map(
                                                  (a) => {
                                                    return {
                                                      ...a,
                                                      product_by_category:
                                                        a.product_by_category.map(
                                                          (b) => {
                                                            return {
                                                              ...b,
                                                              products:
                                                                b.products.map(
                                                                  (c) => {
                                                                    return c.product_id ===
                                                                      item.product_id
                                                                      ? {
                                                                          ...c,
                                                                          quantity:
                                                                            c.quantity +
                                                                            1,
                                                                        }
                                                                      : c;
                                                                  }
                                                                ),
                                                            };
                                                          }
                                                        ),
                                                    };
                                                  }
                                                ),
                                            }));
                                          }}
                                        >
                                          +
                                        </button>
                                      </div>
                                    </TableCell>
                                    <TableCell align="center">
                                      {item.satuan}
                                    </TableCell>
                                    <TableCell align="center">
                                      <p
                                        className="font-semibold text-red-400 cursor-pointer hover:underline"
                                        onClick={() => {
                                          if (
                                            product.products?.length === 1 ||
                                            selectedProductId.length === 1
                                          ) {
                                            setDataBuffer((prevValue) => ({
                                              ...prevValue,
                                              detail_product:
                                                prevValue.detail_product.filter(
                                                  (list) =>
                                                    list.principal_id !==
                                                    data.principal_id
                                                ),
                                            }));
                                          } else {
                                            setDataBuffer((prevValue) => ({
                                              ...prevValue,
                                              detail_product:
                                                prevValue.detail_product.map(
                                                  (a) => {
                                                    return {
                                                      ...a,
                                                      product_by_category:
                                                        a.product_by_category.map(
                                                          (b) => {
                                                            return {
                                                              ...b,
                                                              products:
                                                                b.products.filter(
                                                                  (c) => {
                                                                    return (
                                                                      c.product_id !==
                                                                      item.product_id
                                                                    );
                                                                  }
                                                                ),
                                                            };
                                                          }
                                                        ),
                                                    };
                                                  }
                                                ),
                                            }));
                                          }
                                          setSelectedProductId(
                                            selectedProductId.filter(
                                              (s) => s !== item.product_id
                                            )
                                          );
                                          sessionStorage.setItem(
                                            "id",
                                            selectedProductId.filter(
                                              (s) => s !== item.product_id
                                            )
                                          );
                                        }}
                                      >
                                        Hapus
                                      </p>
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        {/* {data.label ===
                    "PT. Millenial Pharmacon International, Tbk" ? (
                      <div className="flex justify-center mt-3 ">
                        <button className="p-3 bg-orange-500 text-white rounded-lg shadow-md">
                          Checkout Via Aplikasi
                        </button>
                      </div>
                    ) : data.label !==
                      "PT. Millenial Pharmacon International, Tbk" ? ( */}
                        <div className="flex justify-center my-3">
                          <button
                            className="p-3 bg-white text-black rounded-lg border-2 w-full"
                            onClick={() => {
                              setPrintSP({
                                type:
                                  product.golongan_id === 4 ||
                                  product.golongan_id === 5
                                    ? product.golongan_id
                                    : 0,
                                nama_principal: data.principal_name,
                                product: product.products.map((p) => ({
                                  product_id: p.product_id,
                                  quantity:
                                    p.quantity !== null ? p.quantity : 1,
                                })),
                              });
                              navigate("/inventory/print-SP");
                            }}
                          >
                            Cetak SP
                          </button>
                        </div>
                        {/* ) : null} */}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </>
            ) : (
              <p>kosong!</p>
            )}
          </>
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            scroll={"paper"}
            dividers={true}
            fullWidth
          >
            <DialogTitle sx={{ textAlign: "center" }}>
              Tambah Produk
            </DialogTitle>
            <DialogContent>
              <AddProductStockoutContent
                principalId={principalId}
                selectedProductId={selectedProductId}
                setSelectedProductId={setSelectedProductId}
              />
            </DialogContent>
            <DialogActions>
              <div className="flex justify-center w-full h-[40px]">
                <button
                  className="rounded border w-full mr-1"
                  onClick={() => setOpen(false)}
                >
                  Batal
                </button>
                <button
                  className="bg-[#007AF1] rounded text-white w-full"
                  onClick={() => {
                    sessionStorage.setItem("id", selectedProductId);
                    setOpen(false);
                    setTrigger(!trigger);
                  }}
                >
                  Simpan
                </button>
              </div>
            </DialogActions>
          </Dialog>
        </>
      ) : null}
    </div>
  );
};

export default CreatePurchase;
