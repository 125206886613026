import React from "react";
import HOC from "../../components/HOC";
import CreateInvoice from "../../components/screen/invoice/CreateInvoice";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const CreateInvoicePage = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header={"Create Invoice " + (isWarehouse ? "Central Warehouse" : "")}
        mainComponent={<CreateInvoice isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 42 : 11}
      />
    </div>
  );
};

export default HOC(CreateInvoicePage);
