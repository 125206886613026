import React from "react";
import DetailProductInactive from "../../../components/screen/inventory/productInactive/DetailProductInactive";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import HOC from "../../../components/HOC";

const DetailsProductInactivePage = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header="Detail Inventory"
        mainComponent={<DetailProductInactive isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 27 : 4}
      />
    </div>
  );
};

export default HOC(DetailsProductInactivePage);
