import React from "react";
import HOC from "../../components/HOC";
import CreateUser from "../../components/screen/userManagement/CreateUser";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const TambahPengguna = () => {
  return (
    <div className="">
      <PageTemplate
        header="Tambah Pengguna"
        mainComponent={<CreateUser />}
        pageRole={6}
      />
    </div>
  );
};

export default HOC(TambahPengguna);
