import React from "react";
import HOC from "../../components/HOC";
import CreateBillingManual from "../../components/screen/billingManual/CreateBillingManual";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const CreateBillingManualPage = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header={
          "Create Billing Manual " + (isWarehouse ? "Central Warehouse" : "")
        }
        mainComponent={<CreateBillingManual isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 28 : 7}
      />
    </div>
  );
};

export default HOC(CreateBillingManualPage);
