import HOC from "../../../components/HOC";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import PrescriptionList from "../../../components/screen/patientCare/prescriptionHistory/PrescriptionList";

const PrescriptionHistoryListPage = () => {
  return (
    <PageTemplate
      header="Prescription History"
      mainComponent={<PrescriptionList />}
      pageRole={26}
    />
  );
};

export default HOC(PrescriptionHistoryListPage);
