import React from "react";
import CardStockDetailNew from "../../../components/screen/inventory/CardStock/CardStockDetailNew";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import HOC from "../../../components/HOC";

const CardStockDetailNewPage = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header={
          "Detail Kartu Stock " + (isWarehouse ? "Central Warehouse" : "")
        }
        mainComponent={<CardStockDetailNew isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 30 : 24}
      />
    </div>
  );
};

export default HOC(CardStockDetailNewPage);
