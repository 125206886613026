import React from "react";
import UpdateSeveralStock from "../../../components/screen/inventory/productActive/UpdateSeveralStock";
import HOC from "../../../components/HOC";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";

const UpdateSeveralStockActive = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header=""
        mainComponent={<UpdateSeveralStock isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 27 : 4}
      />
    </div>
  );
};

export default HOC(UpdateSeveralStockActive);
