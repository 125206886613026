import React from "react";
import Order from "../../components/screen/Order/Order";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";
import HOC from "../../components/HOC";

const OrderPage = () => {
  return (
    <div className="">
      <PageTemplate header="Pesanan" mainComponent={<Order />} pageRole={2} />
    </div>
  );
};

export default HOC(OrderPage);
