import React from "react";
import HOC from "../../../components/HOC";
import ListRequestWarehouse from "../../../components/screen/centralWarehouse/request/ListRequestWarehouse";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";

const RequestTransferListPage = () => {
  return (
    <div className="">
      <PageTemplate
        header="Request Transfer"
        mainComponent={<ListRequestWarehouse type={5} />}
        pageRole={38}
      />
    </div>
  );
};

export default HOC(RequestTransferListPage);
