import React from "react";
import HOC from "../../../components/HOC";
import DetailAllocation from "../../../components/screen/centralWarehouse/allocation/DetailAllocation";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";

const DetailRequestAllocationPage = () => {
  return (
    <div className="">
      <PageTemplate
        header="Request Allocation Details"
        mainComponent={<DetailAllocation type={4} />}
        pageRole={34}
      />
    </div>
  );
};

export default HOC(DetailRequestAllocationPage);
