import React from "react";
import HOC from "../../components/HOC";
import PoListBilling from "../../components/screen/billingManual/PoListBilling";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const PoListBillingPage = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header={
          (isWarehouse ? "Central Warehouse" : "") + " List Billing Manual"
        }
        mainComponent={<PoListBilling isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 28 : 7}
      />
    </div>
  );
};

export default HOC(PoListBillingPage);
