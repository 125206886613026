import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import HOC from "../../../components/HOC";
import InboxDetailWarehouse from "../../../components/screen/centralWarehouse/inbox/InboxDetailWarehouse";

const DetailInboxWarehousePage = ({ type }) => {
  return (
    <div className="">
      <PageTemplate
        header="Detail Inbox"
        mainComponent={<InboxDetailWarehouse type={type} />}
        pageRole={type === 1 ? 39 : 40}
      />
    </div>
  );
};

export default HOC(DetailInboxWarehousePage);
