import React from "react";
import ImportPurchaseOrder from "../../components/screen/purchaseOrder/ImportPurchaseOrder";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";
import HOC from "../../components/HOC";

const ImportPurchaseOrderPage = () => {
  return (
    <div className="">
      <PageTemplate
        header={"Import Purchase Order"}
        mainComponent={<ImportPurchaseOrder />}
        pageRole={0}
      />
    </div>
  );
};

export default HOC(ImportPurchaseOrderPage);
