import { useEffect, useState } from "react";
import { Button, CircularProgress, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import moment from "moment";
import AddNewConsignmentProduct from "./AddNewConsignmentProduct";
import Header from "../../../ui/Header";
import { useMutation } from "@tanstack/react-query";
import { setNewConsignment } from "../../../../services/consignmentApi";
import { BsPencil, BsTrash } from "react-icons/bs";
import { IGNORE_TYPE } from "../../../constants/constant";

const validationSchema = yup.object({
  invoice: yup.string().required("Nomor faktur wajib diisi"),
  supplier_name: yup.string().required("Nama supplier wajib diisi"),
  transaction_date: yup.string().required("Tanggal transaksi wajib diisi"),
  expired_date_consignment: yup
    .string()
    .required("Tanggal jatuh tempo wajib diisi"),
  total_price: yup.number(),
  product_details: yup.array().min(1, "Faktur minimal memiliki 1 produk"),
});

const AddNewConsignment = () => {
  const navigate = useNavigate();
  const [isAddProduct, setIsAddProduct] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [lastIndex, setLastIndex] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [count, setCount] = useState(0);
  const [qtyType, setQtyType] = useState("");
  const [selectedQtyArr, setSelectedQtyArr] = useState([]);

  const checkIsFirst = () => {
    return (
      formik.values.product_details.length === 1 &&
      formik.values.product_details[0].nie_number === ""
    );
  };

  const handleDeleteProduct = (index) => {
    var temp = [
      ...formik.values.product_details.slice(0, index),
      ...formik.values.product_details.slice(index + 1),
    ];
    formik.setFieldValue("product_details", temp);
  };

  const totalHna = (i) => {
    if (qtyType === formik.values.product_details[i].packaging.type_box) {
      return (
        formik.values.product_details[i].price.pharmacy_net_price *
        primaryCount(
          formik.values.product_details[i].current_stock,
          formik.values.product_details[i]
        )
      );
    }
    if (qtyType === formik.values.product_details[i].packaging.type_strip) {
      return (
        (formik.values.product_details[i].price.pharmacy_net_price /
          formik.values.product_details[i].packaging.total_strip) *
        primaryCount(
          formik.values.product_details[i].current_stock,
          formik.values.product_details[i]
        )
      );
    }
    if (qtyType === formik.values.product_details[i].packaging.type_unit) {
      return (
        (formik.values.product_details[i].price.pharmacy_net_price /
          (formik.values.product_details[i].packaging.total_strip *
            formik.values.product_details[i].packaging.total_unit)) *
        primaryCount(
          formik.values.product_details[i].current_stock,
          formik.values.product_details[i]
        )
      );
    }
  };

  const primaryCount = (stock, item) => {
    if (
      IGNORE_TYPE.includes(item.packaging.type_unit) &&
      item.packaging.total_strip > 0
    ) {
      return Math.floor(stock / item.packaging.total_strip);
    }
    if (item.packaging.total_strip > 0 && item.packaging.total_unit > 0) {
      return Math.floor(
        stock / (item.packaging.total_strip * item.packaging.total_unit)
      );
    } else {
      return stock;
    }
  };

  const primaryStock = (stock, item) => {
    if (IGNORE_TYPE.includes(item.packaging.type_unit)) {
      return (
        Math.floor(stock / item.packaging.total_strip) +
        " " +
        item.packaging.type_strip.toUpperCase()
      );
    } else {
      return (
        Math.floor(
          stock / (item.packaging.total_strip * item.packaging.total_unit)
        ) +
        " " +
        item.packaging.type_box.toUpperCase()
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      invoice: "",
      supplier_name: "",
      transaction_date: moment().format("YYYY-MM-DD"),
      expired_date_consignment: moment().add(1, "days").format("YYYY-MM-DD"),
      total_price: 0,
      product_details: [
        {
          nie_number: "",
          principal_id: 0,
          category_id: [0],
          label_id: 0,
          product_name: "",
          product_image: [""],
          product_description: {
            indication: "",
            composition: "",
            dosage: "",
            usage: "",
            contraindication: "",
            side_effect: "",
            storage_description: "",
          },
          require_prescription: false,
          packaging: {
            type_box: "",
            total_box: 0,
            type_strip: "",
            total_strip: 0,
            type_unit: "",
            total_unit: 0,
          },
          price: {
            pharmacy_net_price: 0,
            sell_price: 0,
            sell_price_primer: 0,
            sell_price_sekunder: 0,
            sell_price_tersier: 0,
            price_grosir: [
              {
                minimum: 0,
                maximum: 0,
                price_grosirs: 0,
              },
            ],
            platform_price: [
              {
                platform_id: 0,
                price: 0,
              },
            ],
          },
          batch: [
            {
              quantity: 0,
              number_batch: "",
              expired_date: "",
            },
          ],
          barcode_number: "",
          current_stock: 0,
          status_reminder_stock: false,
          reminder_stock: 0,
          down_payment: 0,
        },
      ],
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      mutateNewConsignment(values);
    },
  });

  const { mutate: mutateNewConsignment, isLoading } = useMutation({
    mutationFn: setNewConsignment,
    onSuccess: () => {
      navigate(-1);
    },
    onError: (err) => {
      setErrorMessage(err.message.id);
    },
  });

  useEffect(() => {
    checkIsFirst()
      ? setLastIndex(0)
      : setLastIndex(formik.values.product_details.length);
  }, [formik.values]);

  useEffect(() => {
    var result = 0;
    formik.values.product_details.map((_, i) => {
      result += totalHna(i);
    });
    formik.setFieldValue("total_price", result);
  }, [formik.values.product_details]);

  useEffect(() => {
    if (!isAddProduct) setSelectedItem(null);
  }, [isAddProduct]);

  return (
    <>
      <Header />
      <p className="font-semibold text-[20px] lg:text-[30px] mb-3">
        {!isAddProduct ? "Tambah Faktur Konsinyasi" : "Tambah Product"}
      </p>
      {!isAddProduct ? (
        <form onSubmit={formik.handleSubmit} form="consignmentForm">
          <div className="flex justify-between items-center text-white font-bold">
            <p className="text-[#007AF1]">Konsinyasi</p>
            <button className="bg-[007AF1] py-2 px-4 rounded-md">
              Tambah Faktur Konsinyasi
            </button>
          </div>
          <div className="flex gap-3 mt-3">
            <TextField
              label="No. Faktur"
              onChange={(e) => formik.setFieldValue("invoice", e.target.value)}
              value={formik.values.invoice}
              sx={{ width: "100%" }}
            />
            <TextField
              label="Nama Supplier"
              onChange={(e) =>
                formik.setFieldValue("supplier_name", e.target.value)
              }
              value={formik.values.supplier_name}
              sx={{ width: "100%" }}
            />
          </div>
          <div className="flex gap-3 mt-3 mb-4">
            <div className="grid grid-cols-2 gap-3 w-full">
              <DesktopDatePicker
                disableFuture
                label="Tanggal Transaksi"
                onChange={(newTransactionDate) => {
                  formik.setFieldValue(
                    "transaction_date",
                    moment(newTransactionDate).format("YYYY-MM-DD")
                  );
                }}
                value={formik.values.transaction_date}
                renderInput={(params) => {
                  return <TextField {...params} />;
                }}
              />
              <DesktopDatePicker
                disablePast
                minDate={moment().add(1, "days")}
                label="Tanggal Jatuh Tempo"
                onChange={(newDueDate) => {
                  formik.setFieldValue(
                    "expired_date_consignment",
                    moment(newDueDate).format("YYYY-MM-DD")
                  );
                }}
                value={formik.values.expired_date_consignment}
                renderInput={(params) => {
                  return <TextField {...params} />;
                }}
              />
            </div>
          </div>

          <div className="w-full">
            <Button
              sx={{ paddingX: 4, float: "right", marginY: 2 }}
              variant="contained"
              onClick={() => setIsAddProduct(true)}
            >
              Tambah Produk
            </Button>
          </div>

          <div className="w-full my-4 block overflow-x-auto text-sm mt-4">
            <table className="w-full border-2 border-[#5B5B5B]">
              <thead className="bg-[#007AF1] text-white text-center">
                <tr>
                  <th className="p-2 border-2 border-[#5B5B5B]">No.</th>
                  <th className="p-2 border-2 border-[#5B5B5B]">NIE</th>
                  <th className="p-2 border-2 border-[#5B5B5B]">Nama Produk</th>
                  <th className="p-2 border-2 border-[#5B5B5B]">Stok</th>
                  <th className="p-2 border-2 border-[#5B5B5B]">Harga Beli</th>
                  <th className="p-2 border-2 border-[#5B5B5B]">
                    Total Harga Beli
                  </th>
                  <th className="p-2 border-2 border-[#5B5B5B]">
                    Down Payment
                  </th>
                  <th className="p-2 border-2 border-[#5B5B5B]">Kekurangan</th>
                  <th className="p-2 border-2 border-[#5B5B5B]">Action</th>
                </tr>
              </thead>
              <tbody>
                {checkIsFirst() ||
                formik.values.product_details.length === 0 ? (
                  <tr>
                    <td colSpan={9} className="text-gray-300 text-center py-2">
                      Tambahkan produk
                    </td>
                  </tr>
                ) : (
                  <>
                    {formik.values.product_details.map((item, i) => {
                      return (
                        <tr key={i} className="text-center">
                          <td className="p-2 border-2 border-[#5B5B5B]">
                            {i + 1}
                          </td>
                          <td className="p-2 border-2 border-[#5B5B5B]">
                            {item.nie_number}
                          </td>
                          <td className="p-2 border-2 border-[#5B5B5B]">
                            {item.product_name}
                          </td>
                          <td className="p-2 border-2 border-[#5B5B5B]">
                            {primaryStock(item.current_stock, item)}
                          </td>
                          <td className="p-2 border-2 border-[#5B5B5B]">
                            {item.price.pharmacy_net_price.toLocaleString(
                              "id-ID",
                              {
                                style: "currency",
                                currency: "IDR",
                              }
                            )}
                          </td>
                          <td className="p-2 border-2 border-[#5B5B5B]">
                            {totalHna(i)
                              ? totalHna(i).toLocaleString("id-ID", {
                                  style: "currency",
                                  currency: "IDR",
                                })
                              : null}
                          </td>
                          <td className="p-2 border-2 border-[#5B5B5B]">
                            {item.down_payment.toLocaleString("id-ID", {
                              style: "currency",
                              currency: "IDR",
                            })}
                          </td>
                          <td className="p-2 border-2 border-[#5B5B5B]">
                            {totalHna(i)
                              ? (
                                  totalHna(i) - item.down_payment
                                ).toLocaleString("id-ID", {
                                  style: "currency",
                                  currency: "IDR",
                                })
                              : null}
                          </td>{" "}
                          <td className="p-2 border-2 border-[#5B5B5B]">
                            <div className="flex gap-2 items-center justify-center">
                              <BsTrash
                                className="text-red-500 hover:scale-125 duration-75 hover:text-red-700 cursor-pointer"
                                onClick={() => handleDeleteProduct(i)}
                              />
                              <BsPencil
                                onClick={() => {
                                  setIsAddProduct(true);
                                  setSelectedItem(item);
                                  setSelectedIndex(i);
                                }}
                                className="text-green-500 hover:scale-125 duration-75 hover:text-green-700 cursor-pointer"
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                )}
              </tbody>
            </table>
          </div>

          <div className="flex justify-end">
            <div className="w-1/2 flex items-center gap-2">
              <p className="w-full text-right font-bold">Total Tagihan</p>
              <p className="w-full border-2 bg-slate-100 rounded-lg p-2">
                {formik.values.total_price.toLocaleString("id-ID", {
                  style: "currency",
                  currency: "IDR",
                })}
              </p>
            </div>
          </div>

          <p className="text-red-500 w-full text-center">{errorMessage}</p>

          <div className="w-full flex justify-center items-center gap-3 px-[20%] mt-8">
            <Button variant="outlined" fullWidth onClick={() => navigate(-1)}>
              Batal
            </Button>
            <Button
              variant="contained"
              fullWidth
              disabled={
                checkIsFirst |
                isLoading |
                (formik.values.product_details.length === 1 &&
                  formik.values.product_details[0].nie_number === "") |
                !formik.isValid
              }
              type="submit"
              id="consignmentForm"
            >
              {isLoading ? <CircularProgress size={20} /> : "Simpan"}
            </Button>
          </div>
        </form>
      ) : (
        <AddNewConsignmentProduct
          formik={formik}
          lastIndex={lastIndex}
          setIsAddProduct={setIsAddProduct}
          isDetail={selectedItem !== null}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          indexDetail={selectedItem !== null ? selectedIndex : -1}
          count={count}
          setCount={setCount}
          qtyType={qtyType}
          setQtyType={setQtyType}
          setSelectedQtyArr={setSelectedQtyArr}
          selectedQtyArr={selectedQtyArr}
        />
      )}
    </>
  );
};

export default AddNewConsignment;
