import React from "react";
import Chat from "../../components/screen/Chat/Chat";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";
import HOC from "../../components/HOC";

const ChatPage = () => {
  return (
    <div className="">
      <PageTemplate header="" mainComponent={<Chat />} pageRole={2} />
    </div>
  );
};

export default HOC(ChatPage);
