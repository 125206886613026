import React from "react";
import ExportProduct from "../../../components/screen/inventory/productActive/ExportProduct";
import HOC from "../../../components/HOC";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";

const ExportProductPage = ({ isWarehouse }) => {
  return (
    <div>
      <PageTemplate
        header={"Export Product " + (isWarehouse ? "Central Warehouse" : "")}
        mainComponent={<ExportProduct isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 27 : 4}
      />
    </div>
  );
};

export default HOC(ExportProductPage);
