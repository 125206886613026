import { Box, CircularProgress, Modal } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { BsTrash } from "react-icons/bs";
import { RxCross1 } from "react-icons/rx";
import { deleteSales } from "../../../services/salesApi";
import { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ModalDeleteSales = ({
  showModal,
  setShowModal,
  updateValue,
  refetchList,
}) => {
  const [errorMessage, setErrorMessage] = useState("");

  const handleClose = () => {
    setShowModal(false);
  };

  const { mutate: mutateDelete, isLoading } = useMutation({
    mutationFn: deleteSales,
    onSuccess: () => {
      refetchList();
      handleClose();
    },
    onError: (err) =>
      setErrorMessage(
        err.message.id ? err.message.id : err.message ? err.message : err
      ),
  });
  return (
    <>
      <Modal open={showModal} onClose={handleClose}>
        <Box
          sx={{
            ...style,
            maxWidth: "75%",
            overflowY: "auto",
            overflowX: "wrap",
            maxHeight: "50vh",
            padding: 0,
          }}
          className="text-left text-base w-3/4 lg:w-1/3"
        >
          <div className="flex justify-between items-center px-8 py-4">
            <div className="w-12 h-12 bg-red-100 p-1 rounded-full">
              <div className="w-full h-full bg-red-300 p-2 rounded-full">
                <BsTrash className="w-full h-full text-red-600" />
              </div>
            </div>
            <RxCross1
              className="cursor-pointer hover:text-red-500"
              onClick={handleClose}
            />
          </div>

          <div className="px-8 mb-4">
            <p className="font-bold text-lg ">Delete Sales</p>
            <p>Apakah anda ingin menghapus {updateValue.name}?</p>
          </div>

          <p className="px-8 mb-4 text-red-500">{errorMessage}</p>

          <div className="px-8 mb-4 flex gap-2 items-center">
            <button
              type="button"
              className="w-full p-2 rounded-md border-2 border-black hover:bg-gray-300"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="w-full p-2 rounded-md border-2 border-red-500 bg-red-500 text-white hover:bg-gray-300 hover:border-gray-300 disabled:bg-gray-300 disabled:border-none"
              onClick={() => {
                setErrorMessage("");
                mutateDelete(updateValue.id);
              }}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={20} /> : "Delete"}
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ModalDeleteSales;
