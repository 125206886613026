import React from "react";
import HOC from "../../components/HOC";
import UnpaidBillingManual from "../../components/screen/billingManual/UnpaidBillingManual.jsx";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate.jsx";

const UnpaidBillingPage = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header={(isWarehouse ? "Central Warehouse" : "") + " Manual Billing"}
        mainComponent={<UnpaidBillingManual isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 28 : 7}
      />
    </div>
  );
};

export default HOC(UnpaidBillingPage);
