import React from "react";
import EditConsignmentProduct from "../../../components/screen/inventory/Consignment/EditConsignmentProduct";
import HOC from "../../../components/HOC";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";

const EditConsignmentProductPage = () => {
  return (
    <div className="">
      <PageTemplate
        header="Update Detail Inventori"
        mainComponent={<EditConsignmentProduct />}
        pageRole={8}
      />
    </div>
  );
};

export default HOC(EditConsignmentProductPage);
