import React from "react";
import HOC from "../../components/HOC";
import CustomerDetail from "../../components/screen/invoice/CustomerDetail";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const DetailCustomerInvoicePage = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header={"Detail Customer " + (isWarehouse ? "Central Warehouse" : "")}
        mainComponent={<CustomerDetail isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 41 : 10}
      />
    </div>
  );
};

export default HOC(DetailCustomerInvoicePage);
