import React from "react";
import AddNewConsignment from "../../../components/screen/inventory/Consignment/AddNewConsignment";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import HOC from "../../../components/HOC";

const AddNewConsignmentPage = () => {
  return (
    <div className="">
      {/* empty header due to dynamic header within component */}
      <PageTemplate
        header=""
        mainComponent={<AddNewConsignment />}
        pageRole={8}
      />
    </div>
  );
};

export default HOC(AddNewConsignmentPage);
