import React from "react";
import CardStockDetail from "../../../components/screen/CardStock/CardStockDetail";
import HOC from "../../../components/HOC";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";

const CardStockDetailPage = () => {
  return (
    <div className="">
      <PageTemplate
        header={"Detail Kartu Stock"}
        mainComponent={<CardStockDetail />}
      />
    </div>
  );
};

export default HOC(CardStockDetailPage);
