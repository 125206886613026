import React from "react";
import EditDetailProductHold from "../../../components/screen/inventory/productHold/EditDetailProductHold";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import HOC from "../../../components/HOC";

const DetailProductHoldPage = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header={
          "Update Information Inventory " +
          (isWarehouse ? "Central Warehouse" : "")
        }
        mainComponent={<EditDetailProductHold isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 27 : 4}
      />
    </div>
  );
};

export default HOC(DetailProductHoldPage);
