import React from "react";
import Retur from "../../../components/screen/inventory/productExpired/Retur";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import HOC from "../../../components/HOC";

const ReturPage = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header="Return Produk Expired"
        mainComponent={<Retur isWarehouse={isWarehouse} />}
      />
    </div>
  );
};

export default HOC(ReturPage);
