import React from "react";
import DetailConsignmentProduct from "../../../components/screen/inventory/Consignment/DetailConsignmentProduct";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import HOC from "../../../components/HOC";

const DetailConsignmentProductPage = () => {
  return (
    <div className="">
      <PageTemplate
        header="Detail Inventori"
        mainComponent={<DetailConsignmentProduct />}
        pageRole={8}
      />
    </div>
  );
};

export default HOC(DetailConsignmentProductPage);
