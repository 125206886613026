import React from "react";
import HOC from "../../../components/HOC";
import AddAllocation from "../../../components/screen/centralWarehouse/allocation/AddAllocation";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";

const AddLocationPage = () => {
  return (
    <div className="">
      <PageTemplate
        header="New Allocation"
        mainComponent={<AddAllocation />}
        pageRole={34}
      />
    </div>
  );
};

export default HOC(AddLocationPage);
