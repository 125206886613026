import React, { useState } from "react";
import Penjualan from "./allReport/Penjualan";
import Breadcumbs from "../../common/particles.jsx/Breadcumbs";
import InventoryReporting from "./allReport/InventoryReporting";
import Sipnap from "./allReport/Sipnap";
import Purchase from "./allReport/Purchase";
import LogShift from "./LogShift/LogShift";

const Reporting = () => {
  const [page, setPage] = useState(1);
  const [penjualan, setPenjualan] = useState();

  const pageDisplay = () => {
    if (page === 1) {
      return (
        <Penjualan
          setPage={setPage}
          page={page}
          penjualan={penjualan}
          setPenjualan={setPenjualan}
        />
      );
    } else if (page === 2) {
      return <Purchase />;
    } else if (page === 3) {
      return <InventoryReporting />;
    } else if (page === 4) {
      return <Sipnap />;
    }
  };

  return (
    <div>
      <>
        {page === 1 ? (
          <Breadcumbs
            nama1="Reporting"
            nama2="Penjualan"
            link1="/reporting"
            link2="/reporting"
            className="hidden lg:block"
          />
        ) : page === 2 ? (
          <Breadcumbs
            nama1="Reporting"
            nama2="Pembelian"
            slice1="/"
            className="hidden lg:block"
          />
        ) : page === 3 ? (
          <Breadcumbs
            nama1="Reporting"
            nama2="Inventory"
            link1="/reporting"
            link2="/reporting"
            className="hidden lg:block"
          />
        ) : page === 4 ? (
          <Breadcumbs
            nama1="Reporting"
            nama2="SIPNAP"
            link1="/reporting"
            link2="/reporting"
            className="hidden lg:block"
          />
        ) : null}

        <div className="w-full justify-between border-2 mt-14 lg:mt-3 text-sm lg:text-base  text-center grid grid-cols-4 divide-x">
          <button
            onClick={() => {
              setPage(1);
            }}
            className={page === 1 ? "py-2 bg-blue-500 text-white" : "py-2"}
          >
            Penjualan
          </button>
          <button
            onClick={() => {
              setPage(2);
            }}
            className={page === 2 ? "py-2 bg-blue-500 text-white" : "py-2"}
          >
            Pembelian
          </button>
          <button
            onClick={() => {
              setPage(3);
            }}
            className={page === 3 ? "py-2 bg-blue-500 text-white" : "py-2 "}
          >
            Inventory
          </button>
          <button
            onClick={() => {
              setPage(4);
            }}
            className={
              page === 4 ? "py-2 bg-blue-500 disabled:text-gray-300" : "py-2"
            }
            // disabled
          >
            SIPNAP
          </button>
        </div>
      </>
      <div>{pageDisplay()}</div>
    </div>
  );
};

export default Reporting;
