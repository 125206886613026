import React from "react";
import CreateAdjustmentStock from "../../../components/screen/inventory/StockAdjustment/CreateAdjustmentStock";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import HOC from "../../../components/HOC";

const CreateStockAdjustmentPage = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header={
          "Tambah Stock Adjustment " + (isWarehouse ? "Central Warehouse" : "")
        }
        mainComponent={<CreateAdjustmentStock isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 32 : 22}
      />
    </div>
  );
};

export default HOC(CreateStockAdjustmentPage);
