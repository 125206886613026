import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import HOC from "../../../components/HOC";
import InboxWarehouseList from "../../../components/screen/centralWarehouse/inbox/InboxWarehouseList";

const InboxWarehousePage = ({ type }) => {
  return (
    <div className="">
      <PageTemplate
        header={"Inbox " + type === 1 ? "Warehouse" : "Outlet"}
        mainComponent={<InboxWarehouseList type={type} />}
        pageRole={type === 1 ? 39 : 40}
      />
    </div>
  );
};

export default HOC(InboxWarehousePage);
