import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { Close as CloseIcon } from "@mui/icons-material";

import { setSales } from "../../../services/salesApi";
import { useState } from "react";

const validationSchema = yup.object({
  name: yup.string().required("Nama wajib diisi"),
  // phone: yup.string().required("No Telpon wajib diisi"),
  // email: yup.string().email().required("Email wajib diisi"),
  // address: yup.string().required("Alamat wajib diisi"),
});

const CreateSalesModal = (props) => {
  const { isAddSalesOpen, setIsAddSalesOpen, refetchList } = props;

  const [errorMessage, setErrorMessage] = useState("");

  const formik = useFormik({
    initialValues: {
      name: "",
      // phone: "",
      // email: "",
      // address: "",
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      mutateSales(values);
    },
  });

  const { mutate: mutateSales, isLoading } = useMutation({
    mutationFn: setSales,
    onSuccess: () => {
      refetchList();
      setIsAddSalesOpen(false);
      formik.resetForm();
    },

    onError: (err) =>
      setErrorMessage(err.message.id ? err.message.id : err.message),
  });

  return (
    <Dialog
      open={isAddSalesOpen}
      onClose={() => {
        setIsAddSalesOpen(false);
        formik.resetForm();
      }}
      fullWidth
      maxWidth="sm"
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ display: "flex" }}>
          <p className="text-center w-full font-bold">Tambah Sales</p>
          <button
            type="reset"
            onClick={() => {
              setIsAddSalesOpen(false);
              formik.resetForm();
            }}
          >
            <CloseIcon
              sx={{
                textAlign: "end",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            />
          </button>
        </DialogTitle>
        <DialogContent>
          <div className="grid gap-y-3">
            <p className="font-bold">Nama</p>
            <input
              className={`p-2 rounded-md w-full ${
                formik.touched.name && Boolean(formik.errors.name)
                  ? "border-2 border-[#D32F2F]"
                  : "border border-black"
              }`}
              value={formik.values.name}
              helperText={formik.touched.name && formik.errors.name}
              onChange={(e) => formik.setFieldValue("name", e.target.value)}
            />
            {formik.touched.name && Boolean(formik.errors.name) ? (
              <p className="text-[#D32F2F]">{formik.errors.name}</p>
            ) : null}
            {/* <p className="font-bold">No. Telp</p>
            <input
              className={`p-2 rounded-md w-full ${
                formik.touched.phone && Boolean(formik.errors.phone)
                  ? "border-2 border-[#D32F2F]"
                  : "border border-black"
              }`}
              value={formik.values.phone}
              onChange={(e) => formik.setFieldValue("phone", e.target.value)}
            />
            {formik.touched.phone && Boolean(formik.errors.phone) ? (
              <p className="text-[#D32F2F]">{formik.errors.phone}</p>
            ) : null}
            <p className="font-bold">Email</p>
            <input
              className={`p-2 rounded-md w-full ${
                formik.touched.email && Boolean(formik.errors.email)
                  ? "border-2 border-[#D32F2F]"
                  : "border border-black"
              }`}
              value={formik.values.email}
              onChange={(e) => formik.setFieldValue("email", e.target.value)}
            />
            {formik.touched.email && Boolean(formik.errors.email) ? (
              <p className="text-[#D32F2F]">{formik.errors.email}</p>
            ) : null}
            <p className="font-bold">Alamat</p>
            <input
              className={`p-2 rounded-md w-full ${
                formik.touched.address && Boolean(formik.errors.address)
                  ? "border-2 border-[#D32F2F]"
                  : "border border-black"
              }`}
              value={formik.values.address}
              onChange={(e) => formik.setFieldValue("address", e.target.value)}
            />
            {formik.touched.address && Boolean(formik.errors.address) ? (
              <p className="text-[#D32F2F]">{formik.errors.address}</p>
            ) : null} */}
          </div>
          <p className="text-red-500">{errorMessage}</p>
        </DialogContent>
        <DialogActions>
          <div className="flex justify-center w-full h-[40px]">
            <button
              className="rounded border w-full mr-1"
              type="reset"
              onClick={() => {
                setIsAddSalesOpen(false);
                formik.resetForm();
              }}
            >
              Batal
            </button>
            <button
              className="bg-[#007AF1] rounded text-white w-full disabled:bg-gray-300"
              disabled={!formik.isValid || isLoading}
              type="submit"
            >
              {isLoading ? <CircularProgress size={20} /> : "Simpan"}
            </button>
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateSalesModal;
