import React from "react";
import HOC from "../../components/HOC";
import ReportingComponents from "../../components/screen/Reporting/Reporting";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const Reporting = () => {
  return (
    <div className="">
      <PageTemplate
        header="Reporting"
        mainComponent={<ReportingComponents />}
        pageRole={5}
      />
    </div>
  );
};

export default HOC(Reporting);
