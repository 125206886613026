import React from "react";
import CardStock from "../../../components/screen/inventory/CardStock/CardStock";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import HOC from "../../../components/HOC";

const CardStockPage = () => {
  return (
    <div className="">
      <PageTemplate header={"Kartu Stock"} mainComponent={<CardStock />} />
    </div>
  );
};

export default HOC(CardStockPage);
