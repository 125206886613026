import React from "react";
import HOC from "../../../components/HOC";
import TransferList from "../../../components/screen/centralWarehouse/transfer/TransferList";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";

const TransferListPage = () => {
  return (
    <div className="">
      <PageTemplate
        header="Transfer"
        mainComponent={<TransferList />}
        pageRole={35}
      />
    </div>
  );
};

export default HOC(TransferListPage);
