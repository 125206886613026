import React from "react";
import HOC from "../../../components/HOC";
import AllocationList from "../../../components/screen/centralWarehouse/allocation/AllocationList";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";

const AllocationListPage = () => {
  return (
    <div className="">
      <PageTemplate
        header="Allocation"
        mainComponent={<AllocationList />}
        pageRole={34}
      />
    </div>
  );
};

export default HOC(AllocationListPage);
