import React from "react";
import PrintCreatePurchase from "../../../components/screen/inventory/productHabis/PrintCreatePurchase";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import HOC from "../../../components/HOC";

const PrintCreatePurchasePage = () => {
  return (
    <div className="">
      <PageTemplate
        header="Surat Pesanan"
        mainComponent={<PrintCreatePurchase />}
        pageRole={4}
      />
    </div>
  );
};

export default HOC(PrintCreatePurchasePage);
