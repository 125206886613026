import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { TextField, CircularProgress } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment/moment";

import {
  getCustomerDetail,
  getCustomerTransaction,
} from "../../../services/customerApi";
// import BreadcrumbCustomer from "./BreadcrumbCustomer";
import { useMutation, useQuery } from "@tanstack/react-query";
import PagePagination from "../../common/particles.jsx/PagePagination";
import { getSalesDetail } from "../../../services/salesApi";

const toDate = (dateStr) => moment(dateStr).utc().format("DD MMMM YYYY");
const toApiDate = (dateStr) => moment(dateStr).format("YYYY-MM-DD");

const DetailSales = () => {
  const { id = "" } = useParams();

  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);
  const [tanggalMulai, setTanggalMulai] = useState("");
  const [tanggalAkhir, setTanggalAkhir] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const {
    mutate: mutateDetail,
    isLoading,
    data: dataDetail,
  } = useMutation({
    mutationFn: getSalesDetail,
  });

  useEffect(() => {
    mutateDetail({
      id: Number(id),
      start_date: tanggalMulai,
      end_date: tanggalAkhir,
      limit: limit,
      offset: offset,
    });
  }, [tanggalMulai, tanggalAkhir, limit, offset]);

  useEffect(() => {
    if (
      toApiDate(startDate) === "Invalid date" ||
      toApiDate(endDate) === "Invalid date"
    ) {
      setTanggalMulai("");
      setTanggalAkhir("");
    } else {
      setTanggalMulai(toApiDate(startDate));
      setTanggalAkhir(toApiDate(endDate));
    }
  }, [startDate, endDate]);

  return (
    <>
      {/* <BreadcrumbCustomer /> */}
      <div className="flex justify-between items-center">
        {dataDetail && !isLoading && dataDetail ? (
          <>
            <div className="flex justify-between items-center w-full my-4">
              <div className="w-full">
                <p className="font-bold text-xl mb-2">
                  {dataDetail.sales_name}
                </p>
              </div>
              <div className="flex gap-4 w-full justify-end">
                <div>
                  <p>Total Belanja</p>
                  {dataDetail && !isLoading ? (
                    <p className="text-amber-500 text-xl">
                      {dataDetail.total_amount?.toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      })}
                    </p>
                  ) : isLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <p className="text-amber-500 text-xl">0</p>
                  )}
                </div>
                <div>
                  <p>Total</p>
                  {dataDetail && !isLoading ? (
                    <p className="text-xl">{dataDetail.total_transaction}</p>
                  ) : isLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <p className="text-xl">0</p>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : isLoading ? (
          <CircularProgress size={20} />
        ) : (
          <p className="w-full text-center my-4">
            Data detail sales tidak ditemukan
          </p>
        )}
      </div>

      <div className="flex flex-row justify-end gap-2 my-3 mt-5">
        <>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              label="Pilih Tanggal Mulai"
              value={startDate}
              onChange={(newStartDate) => {
                setStartDate(newStartDate);
              }}
              renderInput={(params) => {
                return <TextField {...params} />;
              }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              label="Pilih Tanggal Berakhir"
              value={endDate}
              onChange={(newEndDate) => {
                setEndDate(newEndDate);
              }}
              renderInput={(params) => {
                return <TextField {...params} />;
              }}
              disabled={startDate === null ? true : false}
              shouldDisableDate={(date) => moment(date).isBefore(startDate)}
            />
          </LocalizationProvider>
        </>
      </div>

      <>
        <div className="flex w-full mb-8">
          <div className="w-full">
            <div className="block overflow-x-auto w-full">
              <table className="w-full border-collapse">
                <thead className="bg-blue-500 text-white">
                  <th className="p-2">ID Transaction</th>
                  <th className="p-2 text-left">Tanggal</th>
                  <th className="p-2 text-left">Produk</th>
                  <th className="p-2 text-left">Qty</th>
                  <th className="p-2 text-left">Value</th>
                  <th className="p-2 text-left">Kasir</th>
                </thead>
                <tbody>
                  {dataDetail &&
                  !isLoading &&
                  dataDetail.transaction &&
                  dataDetail.count > 0 ? (
                    dataDetail.transaction.map((item, i) => {
                      return (
                        <tr
                          key={i}
                          className="border-collapse border border-gray-500"
                        >
                          <td
                            className="p-2 text-left border text-blue-500 underline hover:text-blue-700 cursor-pointer"
                            onClick={() =>
                              window.open(
                                `/pelanggan/detail-order/${item.transaction_id}`,
                                "_blank"
                              )
                            }
                          >
                            {item.transaction_id}
                          </td>
                          <td className="p-2 text-left border">
                            {moment(item.created_at).format("DD MMMM YYYY")}
                          </td>
                          <td className="p-2 text-left border">
                            {item.product}
                          </td>
                          <td className="p-2 text-left border">
                            {item.quantity}
                          </td>
                          <td className="p-2 text-left border">
                            {item.price
                              ? item.price.toLocaleString("id-ID", {
                                  style: "currency",
                                  currency: "IDR",
                                })
                              : 0}
                          </td>
                          <td className="p-2 text-left border">{item.kasir}</td>
                        </tr>
                      );
                    })
                  ) : isLoading ? (
                    <tr>
                      <td className="p-2 border text-center" colSpan={6}>
                        <CircularProgress size={20} />
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td className="p-2 border text-center" colSpan={6}>
                        Tidak ada transaksi
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {dataDetail && !isLoading && dataDetail.count > 0 ? (
          <PagePagination
            offset={offset}
            setOffset={(e) => {
              setOffset(e);
            }}
            limit={limit}
            setLimit={(e) => {
              setLimit(e);
            }}
            total={dataDetail.count}
          />
        ) : null}
      </>
    </>
  );
};

export default DetailSales;
