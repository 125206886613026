import React from "react";
import HOC from "../../components/HOC";
import PaidBillingManual from "../../components/screen/billingManual/PaidBillingManual";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const PaidBillingManualPage = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header={(isWarehouse ? "Central Warehouse" : "") + " Manual Billing"}
        mainComponent={<PaidBillingManual isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 28 : 7}
      />
    </div>
  );
};

export default HOC(PaidBillingManualPage);
