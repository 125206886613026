import React from "react";
import HOC from "../../../components/HOC";
import ReturDetailProduct from "../../../components/screen/billing/Retur/ReturDetailProduct";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";

const ReturDetailProductPage = () => {
  return (
    <div className="">
      <PageTemplate
        header="Detail Retur"
        mainComponent={<ReturDetailProduct />}
      />
    </div>
  );
};

export default HOC(ReturDetailProductPage);
