import React from "react";
import HOC from "../../../components/HOC";
import ReturProduct from "../../../components/screen/billing/Retur/ReturProduct";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";

const ReturProductPage = () => {
  return (
    <div className="">
      <PageTemplate
        header="List Retur"
        mainComponent={<ReturProduct />}
        pageRole={0}
      />
    </div>
  );
};

export default HOC(ReturProductPage);
