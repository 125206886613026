import React from "react";
import HOC from "../../components/HOC";
import {
  initialValues,
  validationSchema,
} from "../../components/context/CostumerProviderFormik";
import DetailCustomerComponents from "../../components/screen/customer/DetailCustomer";
import { Formik } from "formik";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const DetailCustomer = () => {
  return (
    <Formik
      validateOnChange
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <div className="">
        <PageTemplate
          header="Detail Pelanggan"
          mainComponent={<DetailCustomerComponents />}
          pageRole={0}
        />
      </div>
    </Formik>
  );
};

export default HOC(DetailCustomer);
