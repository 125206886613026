import React from "react";
import HOC from "../../../components/HOC";
import DetailAllocation from "../../../components/screen/centralWarehouse/allocation/DetailAllocation";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";

const DetailLocationPage = () => {
  return (
    <div className="">
      <PageTemplate
        header="Allocation Details"
        mainComponent={<DetailAllocation type={1} />}
        pageRole={34}
      />
    </div>
  );
};

export default HOC(DetailLocationPage);
