import React from "react";
import OrderDetail from "../../components/screen/Order/OrderDetail";
import HOC from "../../components/HOC";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const OrderDetailPage = () => {
  return (
    <div className="">
      <PageTemplate
        header="Pesanan"
        mainComponent={<OrderDetail />}
        pageRole={2}
      />
    </div>
  );
};

export default HOC(OrderDetailPage);
