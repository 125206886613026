import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import { useParams } from "react-router-dom";

import {
  getDetailDiscount,
  createDiscount,
} from "../../../services/DiscountApi";

const DetailDiscountComponent = () => {
  const { id } = useParams();
  const [detail, setDetail] = useState();
  const [loading, setLoading] = useState(false);
  const [payment, setPayment] = useState();
  const [minimumPurchase, setMinimumPurchase] = useState(false);
  const [usageLimit, setUsageLimit] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [timeStart, setTimeStart] = useState(new Date());
  const [timeEnd, setTimeEnd] = useState(new Date());
  const [everyday, setEveryday] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [promoTime, setPromoTime] = useState(false);

  const [data, setData] = useState({
    promo_name: "",
    discount_method: "",
    discount_type: "",
    discount_value: "",
    minimum_purchase: "",
    customer_type: null,
    usage_limit: 1,
    start_date: "",
    end_date: "",
    discount_time: {
      day: [],
    },
    product: null,
    payment: {
      payment_id: [],
    },
    start_time: "",
    end_time: "",
  });

  const allOptions = payment?.debit?.concat(
    payment.emoney ? payment.emoney : [],
    payment.credit ? payment.credit : [],
    payment.cash ? payment.cash : []
  );

  const handleEverydayChange = (event) => {
    setEveryday(event.target.checked);
    if (event.target.checked) {
      setSelectedDays([0, 1, 2, 3, 4, 5, 6]);
      setData({ ...data, discount_time: { day: [0, 1, 2, 3, 4, 5, 6] } });
    } else {
      setSelectedDays([]);
      setData({ ...data, discount_time: { day: [] } });
    }
  };

  const handleDayChange = (event, index) => {
    if (event.target.checked) {
      setData({ ...data, discount_time: { day: [...selectedDays, index] } });
      setSelectedDays([...selectedDays, index]);
    } else {
      setData({
        ...data,
        discount_time: { day: selectedDays.filter((day) => day !== index) },
      });
      setSelectedDays(selectedDays.filter((day) => day !== index));
    }
  };

  const handleDate = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    const options = { year: "numeric", month: "numeric", day: "2-digit" };
    const startDateFormatted = start.toLocaleDateString("en-US", options);
    const endDateFormatted = end.toLocaleDateString("en-US", options);

    const mulai =
      start.getFullYear() +
      "-" +
      ("0" + (start.getMonth() + 1)).slice(-2) +
      "-" +
      start.getDate();
    const akhir =
      end.getFullYear() +
      "-" +
      ("0" + (end.getMonth() + 1)).slice(-2) +
      "-" +
      end.getDate();

    setDetail({ ...detail, start_date: mulai, end_date: akhir });
  };

  const handleGetDetail = async () => {
    setLoading(true);
    const res = await getDetailDiscount(id);
    setDetail(res);
    setLoading(false);
  };

  const handleUpdate = async () => {
    setLoading(true);
    const body = data;
    const res = await createDiscount(body);

    setLoading(false);
  };

  useEffect(() => {
    handleGetDetail();
  }, []);

  return (
    <div className="container mx-auto mt-16 lg:mt-0">
      <h1 className="font-semibold mt-10">
        Pelanggan akan menggunakan kode promo ini pada saat checkout
      </h1>

      <div className="mt-4 grid grid-cols-1 gap-4">
        <TextField
          id="outlined-basic"
          label="Product Name *"
          variant="outlined"
          fullWidth
          value={detail?.promo_name}
          onChange={(e) => setDetail({ ...detail, promo_name: e.target.value })}
        />

        <div>
          <h3 className="font-semibold text-[20px]">Discount</h3>
          <hr className="border-black mt-2" />
          <div className="flex flex-wrap justify-between mt-2">
            <FormControlLabel
              control={
                <Checkbox
                  value={detail?.discount_method}
                  onChange={(e) =>
                    setDetail({
                      ...detail,
                      discount_method: parseInt(e.target.value),
                    })
                  }
                  checked={detail?.discount_method == 1}
                />
              }
              label="Promo Global"
              sx={{ "& .MuiSvgIcon-root": { fontSize: 38 } }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={detail?.discount_method == 2}
                  value={detail?.discount_method}
                  onChange={(e) =>
                    setDetail({
                      ...detail,
                      discount_method: parseInt(e.target.value),
                    })
                  }
                />
              }
              label="Promo Produk"
              sx={{ "& .MuiSvgIcon-root": { fontSize: 38 } }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={detail?.discount_method == 3}
                  value={detail?.discount_method}
                  onChange={(e) =>
                    setDetail({
                      ...detail,
                      discount_method: parseInt(e.target.value),
                    })
                  }
                />
              }
              label="Promo Metode Pembayaran"
              sx={{ "& .MuiSvgIcon-root": { fontSize: 38 } }}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Discount Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Discount Type"
              value={detail?.discount_type}
              onChange={(e) =>
                setDetail({
                  ...detail,
                  discount_type: parseInt(e.target.value),
                })
              }
            >
              <MenuItem value={1}>Percent (%)</MenuItem>
              <MenuItem value={2}>Fixed (Rp)</MenuItem>
            </Select>
          </FormControl>

          {detail?.discount_type === 1 ? (
            <TextField
              label="Discount Percent"
              id="outlined-start-adornment"
              value={detail?.discount_value}
              onChange={(e) =>
                setDetail({
                  ...detail,
                  discount_value: parseInt(e.target.value),
                })
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">%</InputAdornment>
                ),
              }}
            />
          ) : detail?.discount_type === 2 ? (
            <TextField
              label="Discount Rupiah"
              id="outlined-start-adornment"
              value={detail?.discount_value}
              onChange={(e) =>
                setDetail({
                  ...detail,
                  discount_value: parseInt(e.target.value),
                })
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">Rp</InputAdornment>
                ),
              }}
            />
          ) : null}
        </div>

        <div className="flex flex-wrap">
          <FormControlLabel
            control={
              <Checkbox
                // checked={data.minimum_purchase == 1 ? true : false}
                value={detail?.minimum_purchase}
                onChange={(e) => {
                  setMinimumPurchase(e.target.checked);
                  if (!e.target.checked) {
                    setDetail({ ...detail, minimum_purchase: null });
                  }
                }}
              />
            }
            label="Minimum Purchase"
            sx={{ "& .MuiSvgIcon-root": { fontSize: 38 } }}
          />

          {minimumPurchase === true ? (
            <TextField
              label="Minimum Purchase"
              id="outlined-start-adornment"
              onChange={(e) =>
                setDetail({
                  ...detail,
                  minimum_purchase: parseInt(e.target.value),
                })
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">Rp</InputAdornment>
                ),
              }}
            />
          ) : null}
        </div>

        {detail?.discount_method === 1 ? (
          <>
            <h3 className="font-semibold text-[20px]">Customer & Settings *</h3>
            <hr className="border-black" />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Customer Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Customer Type"
                value={detail?.customer_type}
                onChange={(e) =>
                  setDetail({
                    ...detail,
                    customer_type: parseInt(e.target.value),
                  })
                }
              >
                <MenuItem value={1}>Member</MenuItem>
                <MenuItem value={2}>Everyone</MenuItem>
              </Select>
            </FormControl>
          </>
        ) : detail?.discount_method === 2 ? (
          <>
            <h3 className="font-semibold text-[20px]">Product *</h3>
            <hr className="border-black" />
            {/* <Autocomplete
            multiple
            id="tags-standard"
            options={product}
            getOptionLabel={(option) => option.product_name}
            renderInput={(params) => <TextField {...params} label="Pilih Produk" placeholder="Pilih Produk" />}
          /> */}
          </>
        ) : detail?.discount_method === 3 ? (
          <>
            <h3 className="font-semibold text-[20px]">Payment Method *</h3>
            <hr className="border-black" />
            <Autocomplete
              multiple
              id="tags-standard"
              options={allOptions}
              getOptionLabel={(option) => option.name_payment}
              onChange={(e, newValue) => {
                const ids = newValue.map((item) => item.bank_id);
                setData({
                  ...data,
                  payment: {
                    payment_id: ids,
                  },
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Payment Method"
                  placeholder="Pilih bank"
                />
              )}
            />
          </>
        ) : null}

        <div className="mt-3">
          <h3 className="font-semibold text-[20px]">Usage Limit *</h3>
          <hr className="border-black mt-2" />
          <div className="flex flex-wrap gap-3 items-center">
            <FormControlLabel
              control={
                <Checkbox
                  value={1}
                  //   onChange={(e) => {
                  //     setUsageLimit(e.target.value);
                  //     if (e.target.checked) {
                  //       setData({ ...data, usage_limit: null });
                  //     }
                  //   }}
                  onChange={(e) => {
                    setUsageLimit(e.target.value);
                    if (e.target.checked) {
                      setData({ ...data, usage_limit: null });
                    }
                  }}
                  checked={detail?.usage_limit == 1}
                />
              }
              label="Unlimited"
              sx={{ "& .MuiSvgIcon-root": { fontSize: 38 } }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  value={2}
                  onChange={(e) =>
                    setDetail({
                      ...detail,
                      usage_limit: parseInt(e.target.value),
                    })
                  }
                  checked={detail?.usage_limit == 2}
                />
              }
              label="Limited"
              sx={{ "& .MuiSvgIcon-root": { fontSize: 38 } }}
            />

            {detail?.usage_limit == 2 ? (
              <TextField
                className="mt-3"
                label="Limit"
                id="outlined-start-adornment"
                onChange={(e) =>
                  setDetail({
                    ...detail,
                    usage_limit: parseInt(e.target.value),
                  })
                }
              />
            ) : null}
          </div>
        </div>

        <div className="">
          <h3 className="font-semibold text-[20px]">Time *</h3>
          <hr className="border-black my-3" />
          <p className="font-medium">Start date - End date</p>
          <DatePicker
            className="border-2 border rounded-md p-2"
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={handleDate}
            withPortal
          />
        </div>

        <div className="flex flex-wrap  gap-3">
          <FormControlLabel
            control={
              <Checkbox checked={everyday} onChange={handleEverydayChange} />
            }
            label="Everyday"
            sx={{ "& .MuiSvgIcon-root": { fontSize: 38 } }}
          />

          {everyday === true ? null : (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedDays.includes(6)}
                    onChange={(event) => handleDayChange(event, 6)}
                    value={6}
                  />
                }
                label="Senin"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 38 } }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedDays.includes(1)}
                    onChange={(event) => handleDayChange(event, 1)}
                    value={1}
                  />
                }
                label="Selasa"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 38 } }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedDays.includes(2)}
                    onChange={(event) => handleDayChange(event, 2)}
                    value={2}
                  />
                }
                label="Rabu"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 38 } }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedDays.includes(3)}
                    onChange={(event) => handleDayChange(event, 3)}
                    value={3}
                  />
                }
                label="Kamis"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 38 } }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedDays.includes(4)}
                    onChange={(event) => handleDayChange(event, 4)}
                    value={4}
                  />
                }
                label="Jumat"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 38 } }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedDays.includes(5)}
                    onChange={(event) => handleDayChange(event, 5)}
                    value={5}
                  />
                }
                label="Sabtu"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 38 } }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedDays.includes(0)}
                    onChange={(event) => handleDayChange(event, 0)}
                    value={0}
                  />
                }
                label="Minggu"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 38 } }}
              />
            </>
          )}
        </div>
        <hr className="border-black" />
        <div>
          <FormControlLabel
            control={
              <Checkbox
                value={1}
                onChange={(e) => setPromoTime(e.target.checked)}
                // checked={promoTime == 1}
              />
            }
            label="Set Promo Time (24 Hours Format)"
            sx={{ "& .MuiSvgIcon-root": { fontSize: 38 } }}
          />

          {promoTime === true ? null : (
            <div className="flex flex-wrap gap-3">
              <p className="font-medium mt-3">Start Time</p>
              <DatePicker
                className="border border-black rounded-md p-2"
                selected={timeStart}
                onChange={(date) => {
                  setData({
                    ...data,
                    start_time: moment(date).format("HH:mm"),
                  });
                  setTimeStart(date);
                }}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm"
              />
              <p className="font-medium">End Time</p>
              <DatePicker
                className="border border-black rounded-md p-2"
                selected={timeEnd}
                onChange={(date) => {
                  setData({ ...data, end_time: moment(date).format("HH:mm") });
                  setTimeEnd(date);
                }}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm"
              />
            </div>
          )}
        </div>
      </div>

      {/* <div className="grid grid-cols-2 mt-20 gap-12">
        <button className="bg-white text-black border shadow-md font-semibold p-3 rounded-lg">Batal</button>
        <button className="bg-[#007AF1] text-white shadow-md font-semibold p-3 rounded-lg" onClick={handleCreateDiscount}>
          Konfirmasi
        </button>
      </div> */}
    </div>
  );
};

export default DetailDiscountComponent;
