import React from "react";
import PurchaseOrderList from "../../components/screen/purchaseOrder/PurchaseOrderList";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";
import HOC from "../../components/HOC";

const PurchaseOrder = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header={isWarehouse ? "Central Warehouse" : "" + " Purchase Order"}
        mainComponent={<PurchaseOrderList isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 29 : 9}
      />
    </div>
  );
};

export default HOC(PurchaseOrder);
