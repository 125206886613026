import React from "react";
import HOC from "../../../components/HOC";
import AddTransfer from "../../../components/screen/centralWarehouse/transfer/AddTransfer";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";

const AddTransferPage = () => {
  return (
    <div className="">
      <PageTemplate
        header="New Transfer"
        mainComponent={<AddTransfer />}
        pageRole={35}
      />
    </div>
  );
};

export default HOC(AddTransferPage);
