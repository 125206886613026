import React from "react";
import StockAdjustment from "../../../components/screen/inventory/StockAdjustment/StockAdjustment";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import HOC from "../../../components/HOC";

const StockAdjustmentPage = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header={"Stock Adjustment " + (isWarehouse ? "Central Warehouse" : "")}
        mainComponent={<StockAdjustment isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 32 : 22}
      />
    </div>
  );
};

export default HOC(StockAdjustmentPage);
