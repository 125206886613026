import React from "react";
import HOC from "../../../components/HOC";
import ReturWarehouseList from "../../../components/screen/centralWarehouse/returWarehouse/ReturWarehouseList";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";

const ReturnWarehouseListPage = () => {
  return (
    <div className="">
      <PageTemplate
        header="Retur to Warehouse"
        mainComponent={<ReturWarehouseList />}
        pageRole={36}
      />
    </div>
  );
};

export default HOC(ReturnWarehouseListPage);
