import React from "react";
import HOC from "../../components/HOC";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";
import AddDiscount from "../../components/screen/discount/AddDiscount";

const Discount = () => {
  return (
    <div className="">
      <PageTemplate
        header="Add New Promo"
        mainComponent={<AddDiscount />}
        pageRole={0}
      />
    </div>
  );
};

export default HOC(Discount);
