import React from "react";
import HOC from "../../components/HOC";
import SettingPromo from "../../components/screen/invoice/SettingPromo";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const SettingPromoPage = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header={"Promo " + (isWarehouse ? "Central Warehouse" : "")}
        mainComponent={<SettingPromo isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 43 : 12}
      />
    </div>
  );
};

export default HOC(SettingPromoPage);
