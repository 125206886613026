import React from "react";
import Consignment from "../../../components/screen/inventory/Consignment/Consignment";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import HOC from "../../../components/HOC";

const ConsignmentPage = () => {
  return (
    <div className="">
      <PageTemplate
        header="Konsinyasi"
        mainComponent={<Consignment />}
        pageRole={8}
      />
    </div>
  );
};

export default HOC(ConsignmentPage);
