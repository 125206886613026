import React from "react";
import HOC from "../../../components/HOC";
import DetailAllocation from "../../../components/screen/centralWarehouse/allocation/DetailAllocation";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";

const DetailRequestTransferPage = () => {
  return (
    <div className="">
      <PageTemplate
        header="Request Transfer Details"
        mainComponent={<DetailAllocation type={5} />}
        pageRole={38}
      />
    </div>
  );
};

export default HOC(DetailRequestTransferPage);
