import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import Breadcumbs from "../../../common/particles.jsx/Breadcumbs";
import moment from "moment";

import { groupingProduct } from "../../../../services/InventoryAPI";
import { ExpiredListContext } from "../../../context/ProductExpiredProvider";

const Retur = ({ isWarehouse }) => {
  const navigate = useNavigate();
  const { setPrintRetur } = useContext(ExpiredListContext);
  const [dataBuffer, setDataBuffer] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState([]);

  const { mutate: mutatePrintCetakSP, data: groupingProductData } = useMutation(
    {
      mutationFn: groupingProduct,
    }
  );

  useEffect(() => {
    const id = sessionStorage
      .getItem("id")
      .split(",")
      .map((item) => {
        return parseInt(item, 10);
      });
    setSelectedProductId(id);
    mutatePrintCetakSP({
      product_id: id,
      tipe: 2,
      warehouse: isWarehouse ? isWarehouse : false,
    });
  }, []);

  useEffect(() => {
    setDataBuffer(
      groupingProductData && groupingProductData !== undefined
        ? groupingProductData.detail_product
        : []
    );
  }, [groupingProductData]);

  return (
    <div className="container mx-auto  mt-16 lg:mt-0">
      <div className="flex text-blue-500 gap-2">
        <p
          className="hover:cursor-pointer"
          onClick={() =>
            navigate(
              `${
                isWarehouse ? "/central-warehouse" : ""
              }/inventory/produk-expired`
            )
          }
        >
          Produk Expired
        </p>
        <p>/</p>
        <p>Return produk expired</p>
      </div>
      <div className="mt-5">
        <h1 className="text-center font-bold text-[20px]">Daftar Produk</h1>
      </div>
      <>
        {dataBuffer && dataBuffer !== undefined ? (
          <>
            {dataBuffer.map((data, index) => (
              <React.Fragment key={index}>
                <div className="flex flex-row justify-between mt-20 items-center">
                  <h1 className="my-3 font-semibold">
                    Prinsipal: {data.principal_name}
                  </h1>
                </div>
                {data.product_by_category.map((product, idx) => (
                  <React.Fragment key={idx}>
                    <h1 className="my-3 font-semibold">
                      Golongan:{" "}
                      {product.golongan_id === 4 || product.golongan_id === 5
                        ? product.golongan
                        : "Umum"}
                    </h1>
                    <TableContainer
                      component={Paper}
                      sx={{ width: "100%", marginBottom: 3 }}
                    >
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow className="font-semibold">
                            <TableCell align="center">Nama Obat</TableCell>
                            <TableCell align="center">Kuantitas</TableCell>
                            <TableCell align="center">
                              Tanggal Kadaluarsa
                            </TableCell>
                            <TableCell align="center">No. Order</TableCell>
                            <TableCell align="center">Hapus</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {product.products.map((item, i) =>
                            product.products.length === 0 ? null : (
                              <TableRow
                                key={i}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="center"
                                >
                                  {item.product_name}
                                </TableCell>
                                <TableCell align="center">
                                  {item.quantity !== null ? item.quantity : 0}
                                </TableCell>
                                <TableCell align="center">
                                  {moment(item.expired_date).format(
                                    "DD/MM/YYYY"
                                  )}
                                </TableCell>
                                <TableCell align="center">POA1234312</TableCell>
                                <TableCell align="center">
                                  <p
                                    className="font-semibold text-red-400 cursor-pointer hover:underline"
                                    onClick={() => {
                                      if (
                                        dataBuffer[idx]?.product_by_category[
                                          index
                                        ]?.products.length === 1 &&
                                        selectedProductId.length === 1
                                      ) {
                                        setDataBuffer([
                                          ...dataBuffer.slice(0, index),
                                          ...dataBuffer.slice(index + 1),
                                        ]);
                                        sessionStorage.setItem("id", "");
                                      } else {
                                        setDataBuffer(
                                          dataBuffer.map((a) => {
                                            return {
                                              ...a,
                                              product_by_category:
                                                a.product_by_category.filter(
                                                  (b) => {
                                                    return a.principal_id ===
                                                      data.principal_id
                                                      ? {
                                                          ...b,
                                                          products:
                                                            b.products.splice(
                                                              i,
                                                              1
                                                            ),
                                                        }
                                                      : data;
                                                  }
                                                ),
                                            };
                                          })
                                        );
                                        sessionStorage.setItem(
                                          "id",
                                          selectedProductId.filter(
                                            (s) => s !== item.product_id
                                          )
                                        );
                                      }
                                    }}
                                  >
                                    Hapus
                                  </p>
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/* {data.label ===
                    "PT. Millenial Pharmacon International, Tbk" ? (
                      <div className="flex justify-center mt-3 ">
                        <button className="p-3 bg-orange-500 text-white rounded-lg shadow-md">
                          Checkout Via Aplikasi
                        </button>
                      </div>
                    ) : data.label !==
                      "PT. Millenial Pharmacon International, Tbk" ? ( */}
                    <div className="flex justify-center my-3">
                      <button
                        className="p-3 bg-white text-black rounded-lg border-2 w-full"
                        onClick={() => {
                          setPrintRetur({
                            type: product.golongan_id,
                            nama_principal: data.principal_name,
                            product: product.products.map((p) => ({
                              product_id: p.product_id,
                              bentuk_sediaan: p.bentuk_sediaan,
                              nama_obat: p.product_name,
                              no_order: "",
                              satuan: p.satuan,
                              quantity: p.quantity !== null ? p.quantity : 0,
                            })),
                          });
                          navigate(
                            `${
                              isWarehouse ? "/central-warehouse" : ""
                            }/inventory/print-retur`
                          );
                        }}
                      >
                        Cetak Form Return
                      </button>
                    </div>
                    {/* ) : null} */}
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </>
        ) : (
          <p>kosong!</p>
        )}
      </>
    </div>
  );
};

export default Retur;
