import HOC from "../../../components/HOC";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import AddInitialAnalysis from "../../../components/screen/patientCare/patientCareInitialAnalysis/AddInitialAnalysis";

const AddInitialAnalysisListPage = ({ isDetail }) => {
  return (
    <PageTemplate
      header="Tambah Initial Analysis"
      mainComponent={<AddInitialAnalysis isDetail={isDetail} />}
      pageRole={18}
    />
  );
};

export default HOC(AddInitialAnalysisListPage);
