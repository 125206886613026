import React from "react";
import HOC from "../../components/HOC";
import PaidBilling from "../../components/screen/billing/PaidBilling";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const PaidBillingPage = () => {
  return (
    <div className="">
      <PageTemplate
        header="Billing"
        mainComponent={<PaidBilling />}
        pageRole={7}
      />
    </div>
  );
};

export default HOC(PaidBillingPage);
