import React from "react";
import HOC from "../../components/HOC";
import DetailBilling from "../../components/screen/billing/DetailBilling";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const DetailBillingPage = () => {
  return (
    <div className="">
      <PageTemplate header="Billing Detail" mainComponent={<DetailBilling />} />
    </div>
  );
};

export default HOC(DetailBillingPage);
