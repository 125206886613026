import React, { useState, useEffect, useMemo } from "react";
import Breadcumbs from "../../../common/particles.jsx/Breadcumbs";
import {
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableRow,
  TableHead,
  Paper,
  Pagination,
  CircularProgress,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";

import {
  getDetailShift,
  getDetailShiftTable,
} from "../../../../services/ReportingApi";

const FETCH_LIMIT = 10;

const DetailLogShift = () => {
  const [offset, setOffset] = useState(1);
  const [staffId, setStaffId] = useState("");
  const [sessionId, setSessionId] = useState("");

  const { data: reportingDetailData, isLoading: isReportingDetailLoading } =
    useQuery({
      queryKey: [
        "reporting-detail",
        Number(sessionStorage.getItem("session_id")),
        Number(sessionStorage.getItem("staff_id")),
      ],
      queryFn: () =>
        getDetailShift(
          Number(sessionStorage.getItem("session_id")),
          Number(sessionStorage.getItem("staff_id"))
        ),
    });

  const { data: reportingDetailTableData, isLoading: isReportingTableLoading } =
    useQuery({
      queryKey: ["reporting-table", offset, sessionId, staffId],
      queryFn: () =>
        getDetailShiftTable(sessionId, staffId, FETCH_LIMIT, offset),
    });

  const pageCount = useMemo(() => {
    if (reportingDetailTableData === undefined) return 0;
    else if (reportingDetailTableData.count === undefined) return 0;
    else return Math.ceil(reportingDetailTableData.count / FETCH_LIMIT);
  }, [reportingDetailTableData]);

  useEffect(() => {
    setSessionId(Number(sessionStorage.getItem("session_id")));
    setStaffId(Number(sessionStorage.getItem("staff_id")));
  }, [sessionId, staffId]);

  return (
    <div className="container h-full w-full mt-16 lg:mt-0">
      <Breadcumbs
        nama1="Reporting"
        nama2="Detail Log Shift"
        link1="/reporting/detail-log-shift"
        link2="/reporting/detail-log-shift"
      />
      {isReportingDetailLoading ? (
        <div className="flex justify-center items-center">
          <CircularProgress />
        </div>
      ) : (
        <div className="w-full mt-5">
          {reportingDetailData && reportingDetailData !== undefined ? (
            <div className="grid grid-cols-1 lg:grid-cols-2 text-center gap-4 mt-5">
              <div className="flex flex-col p-2 border rounded">
                <h1 className="font-bold">Summary Shift</h1>
                <div className="flex justify-between mt-3">
                  <p>Summary Shift</p>
                  <p>jeniee kasir</p>
                </div>
                <div className="flex justify-between mt-3">
                  <p>Sold Item</p>
                  <p className="text-blue-500 font-bold">
                    {reportingDetailData.sold_items} items
                  </p>
                </div>
                <div className="flex justify-between mt-3">
                  <p>Started Shift</p>
                  <p>
                    {moment(reportingDetailData.start_shift_utc).format(
                      "HH.mm DD MMM YYYY"
                    )}
                  </p>
                </div>
                <div className="flex justify-between mt-3">
                  <p>Closed Shift</p>
                  <p>
                    {moment(reportingDetailData.closed_shift_local).format(
                      "HH.mm DD MMM YYYY"
                    ) !== "Invalid date"
                      ? moment(reportingDetailData.closed_shift_local).format(
                          "HH.mm DD MMM YYYY"
                        )
                      : "-"}
                  </p>
                </div>
                <div className="flex justify-between mt-3">
                  <p>Next Shift Handled by</p>
                  <p>
                    {reportingDetailData.name_next_shift !== ""
                      ? reportingDetailData.name_next_shift
                      : "-"}
                  </p>
                </div>
                <div className="flex justify-between mt-3">
                  <p>Saldo Awal</p>
                  <p>Rp. {reportingDetailData.saldo_awal}</p>
                </div>
                <div className="flex justify-between mt-3">
                  <p>Hasil Penjualan</p>
                  <p>Rp. {reportingDetailData.hasil_penjualan}</p>
                </div>
                <div className="flex justify-between mt-3">
                  <p>Pengeluaran</p>
                  <p>Rp. {reportingDetailData.pengeluaran}</p>
                </div>
                <div className="flex justify-between mt-3">
                  <p>Diskon</p>
                  <p>Rp. {reportingDetailData.discount}</p>
                </div>
                <div className="flex justify-between mt-3">
                  <p>Total Pendapatan</p>
                  <p>Rp. {reportingDetailData.total_pendapatan}</p>
                </div>

                {reportingDetailData.payment_detail !== null &&
                reportingDetailData.payment_detail.filter(
                  (payment) => payment.TypePaymentID === 1
                ).length !== 0 ? (
                  <>
                    <hr className="border-2 my-4" />
                    <p className="text-left font-bold">
                      Metode Pembayaran Debit
                    </p>
                    {reportingDetailData.payment_detail
                      .filter((payment) => payment.TypePaymentID === 1)
                      .map((payment, index) => (
                        <div className="flex justify-between mt-3" key={index}>
                          <p>{payment.Name}</p>
                          <p>Rp. {payment.TotalAmount}</p>
                        </div>
                      ))}
                  </>
                ) : null}

                {reportingDetailData.payment_detail !== null &&
                reportingDetailData.payment_detail.filter(
                  (payment) => payment.TypePaymentID === 2
                ).length !== 0 ? (
                  <>
                    <hr className="border-2 my-4" />
                    <p className="text-left font-bold">
                      Metode Pembayaran Credit
                    </p>
                    {reportingDetailData.payment_detail
                      .filter((payment) => payment.TypePaymentID === 2)
                      .map((payment, index) => (
                        <div className="flex justify-between mt-3" key={index}>
                          <p>{payment.Name}</p>
                          <p>Rp. {payment.TotalAmount}</p>
                        </div>
                      ))}
                  </>
                ) : null}

                {reportingDetailData.payment_detail !== null &&
                reportingDetailData.payment_detail.filter(
                  (payment) => payment.TypePaymentID === 3
                ).length !== 0 ? (
                  <>
                    <hr className="border-2 my-4" />
                    <p className="text-left font-bold">
                      Metode Pembayaran E-Money
                    </p>
                    {reportingDetailData.payment_detail
                      .filter((payment) => payment.TypePaymentID === 3)
                      .map((payment, index) => (
                        <div className="flex justify-between mt-3" key={index}>
                          <p>{payment.Name}</p>
                          <p>Rp. {payment.TotalAmount}</p>
                        </div>
                      ))}
                  </>
                ) : null}

                {reportingDetailData.payment_detail !== null &&
                reportingDetailData.payment_detail.filter(
                  (payment) => payment.TypePaymentID === 4
                ).length !== 0 ? (
                  <>
                    <hr className="border-2 my-4" />
                    <p className="text-left font-bold">
                      Metode Pembayaran Cash
                    </p>
                    {reportingDetailData.payment_detail
                      .filter((payment) => payment.TypePaymentID === 4)
                      .map((payment, index) => (
                        <div className="flex justify-between mt-3" key={index}>
                          <p>{payment.Name}</p>
                          <p>Rp. {payment.TotalAmount}</p>
                        </div>
                      ))}
                  </>
                ) : null}

                <hr className="border-2 my-4" />
                {reportingDetailData.discount_detail !== null
                  ? reportingDetailData.discount_detail.map(
                      (discount, index) => (
                        <div className="flex justify-between mt-3" key={index}>
                          <p className="text-left font-bold">
                            {discount.type_name_discount}
                          </p>
                          <p>Rp. {discount.Total}</p>
                        </div>
                      )
                    )
                  : null}

                <div className="flex justify-between mt-3">
                  <p>Selisih</p>
                  <p>Rp. {reportingDetailData.selisih}</p>
                </div>
                <div className="flex justify-between mt-3">
                  <p>Saldo Akhir</p>
                  <p>Rp. {reportingDetailData.saldo_akhir}</p>
                </div>
              </div>
              {isReportingTableLoading ? (
                <CircularProgress />
              ) : (
                <>
                  {reportingDetailTableData &&
                  reportingDetailTableData !== undefined &&
                  reportingDetailTableData?.data_table !== null ? (
                    <div className="h-full">
                      <TableContainer component={Paper}>
                        <Table sx={{ width: "100%" }} aria-label="simple table">
                          <TableHead>
                            <TableRow sx={{ backgroundColor: "#007AF1" }}>
                              <TableCell
                                align="center"
                                sx={{ color: "white", fontWeight: "bold" }}
                              >
                                Items
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ color: "white", fontWeight: "bold" }}
                              >
                                Quantity
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ color: "white", fontWeight: "bold" }}
                              >
                                Price
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {reportingDetailTableData.data_table.map(
                              (item, index) => (
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                  key={index}
                                >
                                  <TableCell
                                    component="th"
                                    scope="col"
                                    align="center"
                                  >
                                    {item.NameMedicine}
                                  </TableCell>
                                  <TableCell align="center">
                                    {item.Quantity}
                                  </TableCell>
                                  <TableCell align="center">
                                    Rp. {item.TotalPrice}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <div className="flex justify-center mt-3">
                        <Pagination
                          count={pageCount}
                          page={offset}
                          onChange={(_, value) => setOffset(value)}
                          shape="rounded"
                          size="large"
                          color="primary"
                        />
                      </div>
                    </div>
                  ) : (
                    <p>tidak ada tabel</p>
                  )}
                </>
              )}
            </div>
          ) : (
            <p>tidak ada</p>
          )}
        </div>
      )}
    </div>
  );
};

export default DetailLogShift;
