import React from "react";
import HOC from "../../../components/HOC";
import ProductActive from "../../../components/screen/inventory/productActive/ProductActive";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";

const ProductActivePage = ({ isWarehouse }) => {
  return (
    <div>
      <PageTemplate
        header={"Inventory " + (isWarehouse ? "Central Warehouse" : "")}
        mainComponent={<ProductActive isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 27 : 4}
      />
    </div>
  );
};

export default HOC(ProductActivePage);
