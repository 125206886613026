import React from "react";
import ProductExpiredComponents from "../../../components/screen/inventory/productExpired/ProductExpired";
import HOC from "../../../components/HOC";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";

const ProductExpiredPage = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header={"Inventory " + (isWarehouse ? "Central Warehouse" : "")}
        mainComponent={<ProductExpiredComponents isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 27 : 4}
      />
    </div>
  );
};

export default HOC(ProductExpiredPage);
