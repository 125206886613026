import React from "react";
import HOC from "../../components/HOC";
import WaitingBilling from "../../components/screen/billing/WaitingBilling";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const WaitingBillingPage = () => {
  return (
    <div className="">
      <PageTemplate
        header="Menunggu Pembayaran"
        mainComponent={<WaitingBilling />}
      />
    </div>
  );
};

export default HOC(WaitingBillingPage);
