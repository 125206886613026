import React from "react";
import ReportingProduct from "../../../components/screen/Reporting/ReportingProduct/ReportingProduct";
import HOC from "../../../components/HOC";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";

const ReportingProductPage = () => {
  return (
    <div className="">
      <PageTemplate
        header="Laporan"
        mainComponent={<ReportingProduct />}
        pageRole={5}
      />
    </div>
  );
};

export default HOC(ReportingProductPage);
