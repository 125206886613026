import React from "react";
import HOC from "../../components/HOC";
import CreateDraftBilling from "../../components/screen/billingManual/CreateDraftBilling";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const CreateDraftBillingPage = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header={
          "Create Billing Manual " + (isWarehouse ? "Central Warehouse" : "")
        }
        mainComponent={<CreateDraftBilling isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 28 : 7}
      />
    </div>
  );
};

export default HOC(CreateDraftBillingPage);
