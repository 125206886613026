import React from "react";
import HOC from "../../components/HOC";
import SalesComponents from "../../components/screen/sales/Sales";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const Sales = () => {
  return (
    <div className="">
      <PageTemplate
        header="Sales"
        mainComponent={<SalesComponents />}
        pageRole={0}
      />
    </div>
  );
};

export default HOC(Sales);
