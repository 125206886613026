import React, { useState, useMemo } from "react";
import { RxPlusCircled } from "react-icons/rx";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
} from "@mui/material";
import { Link } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import { useQuery } from "@tanstack/react-query";
import {
  getUserList,
  userActivation,
} from "../../../services/userManagementApi";
import UserList from "./UserList";
import ModalActivateUser from "../../common/particles.jsx/ModalActivateUser";
import ModalDeactivateUser from "../../common/particles.jsx/ModalDeactivateUser";

const FETCH_LIMIT = 10;

const UserManagement = () => {
  const [userEmail, setUserEmail] = useState("");
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(1);
  const [openActivateUserModal, setOpenActivateUserModal] = useState(false);
  const [openDeactivateUserModal, setOpenDeactivateUserModal] = useState(false);

  const debounce = useDebounce(search, 500);

  const { data: users } = useQuery({
    queryKey: ["users", debounce, offset],
    queryFn: () => getUserList(FETCH_LIMIT, offset, debounce),
  });

  const pageCount = useMemo(() => {
    if (users && users !== undefined)
      return Math.ceil(users.count / FETCH_LIMIT);
    return;
  }, [users]);

  return (
    <div className="container mx-auto  mt-16 lg:mt-0">
      <ModalActivateUser
        openActivateUserModal={openActivateUserModal}
        setOpenActivateUserModal={setOpenActivateUserModal}
        userEmail={userEmail}
      />

      <ModalDeactivateUser
        openDeactivateUserModal={openDeactivateUserModal}
        setOpenDeactivateUserModal={setOpenDeactivateUserModal}
        userEmail={userEmail}
      />

      <h1 className="text-center font-bold text-[20px] mt-5">List User</h1>
      <div className="flex flex-wrap items-center gap-3 mt-3">
        <div className="w-full lg:w-[60%] shadow-md">
          <label htmlFor="search" className="sr-only">
            Search{" "}
          </label>
          <div methode="get" action="#" className="relative">
            <button
              type="submit"
              id="searchsubmit"
              className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
            >
              <svg
                className="h-5 w-5 text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
            <input
              onChange={(e) => setSearch(e.target.value)}
              type="text"
              className="block w-full pl-10 pr-3 py-2 border rounded-md leading-5 bg-white text-black placeholder-gray-400 sm:text-sm transition duration-150 ease-in-out"
              placeholder="Cari Nama Pengguna"
            />
          </div>
        </div>
        <div className="w-full lg:w-[37%] text-center">
          <Link to="/user-management/create-user">
            <button
              className={`bg-[#007AF1] hover:bg-grey-200 w-full  font-normal  py-2 px-[20px] rounded-lg inline-flex text-center items-center justify-center gap-3`}
            >
              <span className={`text-white`}>
                <RxPlusCircled />
              </span>
              <span className={`text-white`}>Tambah Pengguna</span>
            </button>
          </Link>
        </div>
      </div>
      {users && users !== undefined && users?.data !== null ? (
        <div>
          <TableContainer component={Paper} className="mt-3">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Nama & Akses Pengguna</TableCell>
                  <TableCell align="center">Status</TableCell>
                </TableRow>
              </TableHead>
              <>
                {users.data.map((item) => {
                  return (
                    <TableBody key={item.id}>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="col" align="center">
                          <div className="flex flex-row gap-2 items-center">
                            <img
                              src={item.profile_photo}
                              alt=""
                              className="w-10 rounded-full"
                            />
                            <div className="flex flex-col">
                              <p className="text-start font-medium">
                                <Link
                                  to={`/user-management/detail-user/${item.id}/${item.company_id}`}
                                >
                                  {item.fullname}
                                </Link>
                              </p>
                              <p className="text-[10px] text-start">
                                {item.title_name}
                              </p>
                              <p className="text-[10px] text-start">
                                {item.company_name}
                              </p>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          <UserList
                            item={item}
                            statusCodeUser={item.status_code_user}
                            statusUser={item.status_user}
                            setUserEmail={setUserEmail}
                            setOpenDeactivateUserModal={
                              setOpenDeactivateUserModal
                            }
                            setOpenActivateUserModal={setOpenActivateUserModal}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })}
              </>
            </Table>
          </TableContainer>
          <div className="flex justify-center mt-[10px]">
            <Pagination
              count={pageCount}
              page={offset}
              onChange={(_, value) => setOffset(value)}
              shape="rounded"
              size="large"
              color="primary"
            />
          </div>
        </div>
      ) : (
        <div className="my-5">
          <p className="text-center">Data Tidak ditemukan</p>
        </div>
      )}
    </div>
  );
};

export default UserManagement;
