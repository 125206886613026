import React from "react";
import HOC from "../../components/HOC";
import PaymentMethodComponents from "../../components/screen/settings/PaymentMethod";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const PaymentMethod = () => {
  return (
    <div className="">
      <PageTemplate
        header="Payment Method"
        mainComponent={<PaymentMethodComponents />}
        pageRole={0}
      />
    </div>
  );
};

export default HOC(PaymentMethod);
