import React from "react";
import DetailProductHold from "../../../components/screen/inventory/productHold/DetailProductHold";
import HOC from "../../../components/HOC";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";

const DetailsProductHoldPage = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header="Detail Inventory"
        mainComponent={<DetailProductHold isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 27 : 4}
      />
    </div>
  );
};

export default HOC(DetailsProductHoldPage);
