import React from "react";
import EditDetailsProducts from "../../../components/screen/inventory/productActive/EditDetailsProducts";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import HOC from "../../../components/HOC";

const EditDetailsProductActive = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header={
          "Update Information Inventory " +
          (isWarehouse ? "Central Warehouse" : "")
        }
        mainComponent={<EditDetailsProducts isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 27 : 4}
      />
    </div>
  );
};

export default HOC(EditDetailsProductActive);
