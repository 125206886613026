import HOC from "../../../components/HOC";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import PrescriptionDetail from "../../../components/screen/patientCare/prescriptionHistory/PrescriptionDetail";

const DetailPrescriptionPage = () => {
  return (
    <PageTemplate
      header="Detail Prescription"
      mainComponent={<PrescriptionDetail />}
      pageRole={26}
    />
  );
};

export default HOC(DetailPrescriptionPage);
