import React from "react";
import DetailConsignment from "../../../components/screen/inventory/Consignment/DetailConsignment";
import HOC from "../../../components/HOC";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";

const DetailConsignmentPage = () => {
  return (
    <div className="">
      <PageTemplate
        header="Konsinyasi"
        mainComponent={<DetailConsignment />}
        pageRole={8}
      />
    </div>
  );
};

export default HOC(DetailConsignmentPage);
