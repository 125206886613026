import React from "react";
import DetailProductExpired from "../../../components/screen/inventory/productExpired/DetailProductExpired";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import HOC from "../../../components/HOC";

const DetailsProductExpiredPage = () => {
  return (
    <div className="">
      <PageTemplate
        header=""
        mainComponent={<DetailProductExpired />}
        pageRole={4}
      />
    </div>
  );
};

export default HOC(DetailsProductExpiredPage);
