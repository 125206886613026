import React from "react";
import HOC from "../../components/HOC";
import UserManagementComponents from "../../components/screen/userManagement/UserManagement";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const UserManagement = () => {
  return (
    <div className="">
      <PageTemplate
        header="User Management"
        mainComponent={<UserManagementComponents />}
        pageRole={6}
      />
    </div>
  );
};

export default HOC(UserManagement);
