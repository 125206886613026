import React from "react";
import CreatePurchaseOrder from "../../components/screen/purchaseOrder/CreatePurchaseOrder";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";
import HOC from "../../components/HOC";

const PurchaseOrder = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header=""
        mainComponent={<CreatePurchaseOrder isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 29 : 9}
      />
    </div>
  );
};

export default HOC(PurchaseOrder);
