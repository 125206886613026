import React, { useState, useCallback, useMemo, useEffect } from "react";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Paper,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
  TableContainer,
  Pagination,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { useQuery, useMutation } from "@tanstack/react-query";
import moment from "moment";
import DatePicker from "react-datepicker";
import { usePapaParse } from "react-papaparse";
import { TiExport } from "react-icons/ti";

import {
  fetchReportingShiftTable,
  getListStaff,
} from "../../../../services/ReportingApi";
import { Link } from "react-router-dom";

const FETCH_LIMIT = 10;
const toApiDate = (date) => moment(date).format("YYYY-MM-DD");
const toDate = (date) => moment(date).format("DD-MM-YYYY");

const LogShift = () => {
  const { jsonToCSV } = usePapaParse();
  const [offset, setOffset] = useState(1);
  const [tanggalMulai, setTanggalMulai] = useState("");
  const [tanggalAkhir, setTanggalAkhir] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [date, setDate] = useState(false);
  const [periode, setPeriode] = useState(3);
  const [periodSales, setPeriodSales] = useState(11);
  const [staffId, setStaffId] = useState(8);

  const {
    data: reportingTableData,
    mutate: mutateReportingTable,
    isLoading: isTableLoading,
  } = useMutation({
    mutationFn: fetchReportingShiftTable,
  });

  const { data: listStaff } = useQuery({
    queryKey: ["staff"],
    queryFn: () => getListStaff(FETCH_LIMIT, offset),
  });

  const onChange = useCallback(
    (dates) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    },
    [startDate, endDate]
  );

  const handleJsonToCsvData = (csvData) => {
    const csv = jsonToCSV(csvData.data_table);
    const blob = new Blob([csv], { type: "application/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.download = "history-shift-OLIN.csv";
    a.href = url;

    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  };

  const pageCount = useMemo(() => {
    if (reportingTableData === undefined) return 0;
    else if (reportingTableData.count === undefined) return 0;
    else return Math.ceil(reportingTableData.count / FETCH_LIMIT);
  }, [reportingTableData]);

  useEffect(() => {
    mutateReportingTable({
      limit: FETCH_LIMIT,
      offset: offset,
      periode_sales: periode,
      staff_id: staffId,
      start_date: tanggalMulai,
      end_date: tanggalAkhir,
    });
  }, [offset, periode, staffId, tanggalMulai, tanggalAkhir]);

  useEffect(() => {
    if (
      toApiDate(startDate) === "Invalid date" ||
      toApiDate(endDate) === "Invalid date"
    ) {
      setTanggalMulai("");
      setTanggalAkhir("");
    } else {
      setTanggalMulai(toApiDate(startDate));
      setTanggalAkhir(toApiDate(endDate));
    }
  }, [startDate, endDate]);

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  return (
    <div className="container mx-auto mt-16 lg:mt-0">
      <hr className="border-2 mb-4 lg:mb-0" />
      <div className="flex items-center w-full">
        <p className="font-bold w-[50%]">Pilih Periode Pelaporan</p>
        <FormControl sx={{ marginX: 1, width: "70%" }}>
          <InputLabel id="demo-simple-select-label">Semua Periode</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="periode"
            label="Semua Periode"
            value={periodSales}
            onChange={(e) => {
              if (e.target.value === 7) {
                setDate(!date);
              } else if (e.target.value !== 7) {
                setDate(false);
                setStartDate(null);
                setEndDate(null);
              } else if (e.target.value === 10) {
                return setPeriode(9);
              }
              setPeriode(e.target.value);
              setPeriodSales(e.target.value);
            }}
          >
            <MenuItem value={8}>Hari ini</MenuItem>
            <MenuItem value={9}>Kemarin</MenuItem>
            <MenuItem value={1}>Minggu Berjalan</MenuItem>
            <MenuItem value={2}>Minggu Lalu</MenuItem>
            <MenuItem value={3}>Bulan Berjalan</MenuItem>
            <MenuItem value={4}>Bulan Lalu</MenuItem>
            <MenuItem value={5}>Tahun Berjalan</MenuItem>
            <MenuItem value={6}>Tahun Lalu</MenuItem>
            <MenuItem value={7}>Pilih Tanggal</MenuItem>
            <MenuItem value={10} disabled sx={{ display: "none" }}>
              {toDate(tanggalMulai) + " - " + toDate(tanggalAkhir)}
            </MenuItem>
            <MenuItem value={11}>Semua Data</MenuItem>
          </Select>
        </FormControl>
        <p className="font-bold w-[50%]">Daftar Staff</p>
        <FormControl sx={{ marginX: 1, width: "50%" }}>
          <InputLabel id="demo-simple-select-label">Semua Staf</InputLabel>
          <Select
            label="Staff"
            value={staffId}
            onChange={(e) => {
              setStaffId(e.target.value);
            }}
          >
            {listStaff && listStaff !== undefined ? (
              listStaff.map((staff) => (
                <MenuItem key={staff.staff_id} value={staff.staff_id}>
                  {staff.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value={0} sx={{ display: "none" }} disabled></MenuItem>
            )}
          </Select>
        </FormControl>
        {/* {reportingTableData?.data_table !== null ? ( */}
        <div className="flex justify-end w-full">
          <button
            type="button"
            onClick={() => handleJsonToCsvData(reportingTableData)}
          >
            <TiExport className="text-[50px]" />
          </button>
        </div>
        {/* ) : null} */}
      </div>

      <div className="grid grid-cols-1">
        {isTableLoading ? (
          <div className="flex justify-center mt-5">
            <CircularProgress />
          </div>
        ) : (
          <>
            {reportingTableData && reportingTableData?.data_table !== null ? (
              <>
                <TableContainer component={Paper} className="mt-3">
                  <Table sx={{ width: "100%" }} aria-label="simple table">
                    <TableHead>
                      <TableRow sx={{ backgroundColor: "#007AF1" }}>
                        <TableCell
                          align="center"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          Nama
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          Mulai Shift
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          Selesai Shift
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          Perkiraan Total
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          Total Aktual
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          Perbedaan
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reportingTableData.data_table.map((item, index) => (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          key={index}
                        >
                          <TableCell component="th" scope="col" align="center">
                            <Link
                              to={"/reporting/detail-log-shift"}
                              onClick={() => {
                                sessionStorage.setItem(
                                  "session_id",
                                  item.session_id
                                );
                                sessionStorage.setItem(
                                  "staff_id",
                                  item.staff_id
                                );
                              }}
                            >
                              {item.name}
                            </Link>
                          </TableCell>
                          <TableCell align="center">
                            {item.start_shift}
                          </TableCell>
                          <TableCell align="center">
                            {item.close_shift
                              ? item.close_shift
                              : "belum selesai"}
                          </TableCell>
                          <TableCell align="center">
                            Rp. {item.expected_total ? item.expected_total : 0}
                          </TableCell>
                          <TableCell align="center">
                            Rp. {item.actual_total ? item.actual_total : 0}
                          </TableCell>
                          <TableCell align="center">
                            Rp. {item.difference ? item.difference : 0}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="flex justify-center mt-[10px]">
                  <Pagination
                    count={pageCount}
                    page={offset}
                    onChange={(_, value) => setOffset(value)}
                    shape="rounded"
                    size="large"
                    color="primary"
                  />
                </div>
              </>
            ) : (
              <p className="text-center">Tidak ditemukan!</p>
            )}
          </>
        )}
      </div>
      <Dialog
        open={date}
        onClose={() => {
          setDate(false);
        }}
      >
        <DialogTitle>Pilih Tanggal</DialogTitle>
        <DialogContent>
          <div className="flex">
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
          </div>
          <button
            className="bg-indigo-500 rounded text-white px-2 py-1 font-semibold"
            onClick={() => {
              setDate(false);
              setPeriodSales(10);
            }}
          >
            ok
          </button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LogShift;
