import React from "react";
import PrintRetur from "../../../components/screen/inventory/productExpired/PrintRetur";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import HOC from "../../../components/HOC";

const PrintReturPage = () => {
  return (
    <div className="">
      <PageTemplate
        header="Surat Retur"
        mainComponent={<PrintRetur />}
        pageRole={4}
      />
    </div>
  );
};

export default HOC(PrintReturPage);
