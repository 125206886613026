import React from "react";
import HOC from "../../components/HOC";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";
import DetailDiscountComponent from "../../components/screen/discount/DetailDiscountComponent";

const DetailDiscount = () => {
  return (
    <div className="">
      <PageTemplate
        header="Edit Promo"
        mainComponent={<DetailDiscountComponent />}
        pageRole={0}
      />
    </div>
  );
};

export default HOC(DetailDiscount);
