import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Popover } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { MoreHoriz as MoreHorizIcon } from "@mui/icons-material";
import { AiOutlinePlusCircle } from "react-icons/ai";

import useDebounce from "../../hooks/useDebounce";
// import BreadcrumbCustomer from "./BreadcrumbCustomer";
import PagePagination from "../../common/particles.jsx/PagePagination";
import CreateSalesModal from "./CreateSalesModal";
import ModalChangeSales from "./ModalChangeSales";
import { getSales } from "../../../services/salesApi";
import { BsPencil, BsTrash } from "react-icons/bs";
import ModalDeleteSales from "./ModalDeleteSales";

const Sales = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [isAddSalesOpen, setIsAddSalesOpen] = useState(false);
  const [updateValue, setUpdateValue] = useState({
    id: 0,
    name: "",
    email: "",
    address: "",
    phone: "",
  });
  const debounce = useDebounce(search, 500);

  const refetchList = (searchStr) => {
    mutateSales({
      name: searchStr,
      limit: limit,
      offset: offset,
    });
  };

  const {
    mutate: mutateSales,
    data: dataSales,
    isLoading: isSalesLoading,
  } = useMutation({
    mutationFn: getSales,
  });

  useEffect(() => {
    refetchList(debounce);
  }, [offset, limit, debounce]);

  useEffect(() => {
    setOffset(1);
  }, [debounce]);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (search === "") {
      setOffset(Number("1"));
    }
  }, [search, setOffset]);

  return (
    <>
      {/* <BreadcrumbCustomer /> */}
      <div className="flex gap-2 w-full items-center mb-4">
        <div className="w-full shadow-md rounded-lg">
          <label htmlFor="search" className="sr-only">
            Search{" "}
          </label>
          <div methode="get" action="#" className="relative z-10">
            <button
              type="submit"
              id="searchsubmit"
              className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
            >
              <svg
                className="h-5 w-5 text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
            <input
              type="text"
              className="block w-full pl-10 pr-3 py-2 border rounded-md leading-5 bg-white text-black placeholder-gray-400  sm:text-sm transition duration-150 ease-in-out"
              placeholder="Search"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              value={search}
            />
          </div>
        </div>
        <div
          className="hover:cursor-pointer py-2 px-3 gap-2 text-white font-medium rounded-md bg-[#007AF1] flex justify-between items-center"
          onClick={() => setIsAddSalesOpen(true)}
        >
          <AiOutlinePlusCircle className="text-[20px]" />
          <p className="whitespace-nowrap">Tambah Sales</p>
        </div>
      </div>

      <div className="flex w-full mb-8">
        <div className="w-full">
          <div className="block overflow-x-auto w-full">
            <table className="w-full border-collapse">
              <thead className="bg-blue-500 text-white">
                <th className="p-2 text-left">Sales Name</th>
                <th className="p-2 text-center w-[150px]"></th>
              </thead>
              <tbody>
                {dataSales &&
                !isSalesLoading &&
                dataSales.data !== null &&
                dataSales.data ? (
                  dataSales.data.map((item, i) => {
                    return (
                      <tr
                        key={i}
                        className="border-collapse border border-gray-500"
                      >
                        <td
                          className="p-2 text-left border text-blue-500 cursor-pointer hover:text-gray-300"
                          onClick={() =>
                            navigate(`/sales/detail-sales/${item.id}`)
                          }
                        >
                          {item.name === "" ? "-" : item.name}
                        </td>
                        <td className="p-2 text-center flex justify-center w-[150px] gap-2">
                          <button
                            className="w-[28px] h-[28px] rounded-lg p-1 flex items-center justify-center bg-blue-500 text-white hover:bg-gray-100"
                            onClick={() => {
                              setUpdateValue({
                                id: item.id,
                                name: item.name,
                              });
                              setShowModal(true);
                            }}
                          >
                            <BsPencil className="text-white w-full h-full" />
                          </button>
                          <button
                            className="w-[28px] h-[28px] rounded-lg p-1 flex items-center justify-center bg-red-500 text-white hover:bg-gray-100"
                            onClick={() => {
                              setUpdateValue({
                                id: item.id,
                                name: item.name,
                              });
                              setShowDeleteModal(true);
                            }}
                          >
                            <BsTrash className="text-white w-full h-full" />
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : isSalesLoading ? (
                  <tr>
                    <td className="p-2 text-center border-2" colSpan={2}>
                      <CircularProgress size={20} />
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td className="p-2 text-center border-2" colSpan={2}>
                      Tidak ada sales
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {dataSales && dataSales.data && !isSalesLoading && dataSales.count > 0 ? (
        <PagePagination
          offset={offset}
          setOffset={(e) => {
            setOffset(e);
          }}
          limit={limit}
          setLimit={(e) => {
            setLimit(e);
          }}
          total={dataSales.count}
        />
      ) : null}
      <CreateSalesModal
        isAddSalesOpen={isAddSalesOpen}
        setIsAddSalesOpen={setIsAddSalesOpen}
        refetchList={() => refetchList("")}
      />
      <ModalChangeSales
        setShowModal={setShowModal}
        showModal={showModal}
        updateValue={updateValue}
        refetchList={() => refetchList("")}
      />
      <ModalDeleteSales
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        updateValue={updateValue}
        refetchList={() => refetchList("")}
      />
    </>
  );
};

export default Sales;
