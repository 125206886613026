import React from "react";
import HOC from "../../components/HOC";
import UnpaidBilling from "../../components/screen/billing/UnpaidBilling";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const UnpaidBillingPage = () => {
  return (
    <div className="">
      <PageTemplate
        header="Billing"
        mainComponent={<UnpaidBilling />}
        pageRole={7}
      />
    </div>
  );
};

export default HOC(UnpaidBillingPage);
