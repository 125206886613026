import React from "react";
import ImportProduct from "../../../components/screen/inventory/productActive/ImportProduct";
import HOC from "../../../components/HOC";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";

const ImportActiveProduct = ({ isWarehouse }) => {
  return (
    <div>
      <PageTemplate
        header={"Import Product " + (isWarehouse ? "Central Warehouse" : "")}
        mainComponent={<ImportProduct isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 27 : 4}
      />
    </div>
  );
};

export default HOC(ImportActiveProduct);
