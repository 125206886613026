import React from "react";
import LogShift from "../../../components/screen/Reporting/LogShift/LogShift";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import HOC from "../../../components/HOC";

const LogShiftPage = () => {
  return (
    <div className="">
      <PageTemplate
        header="Log Shift"
        mainComponent={<LogShift />}
        pageRole={5}
      />
    </div>
  );
};

export default HOC(LogShiftPage);
