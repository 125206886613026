import React from "react";
import InventorySummary from "../../../components/screen/inventory/InventorySummary/InventorySummary";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import HOC from "../../../components/HOC";

const InventorySummaryPage = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header={isWarehouse ? "Central Warehouse" : "" + " Inventory Summary"}
        mainComponent={<InventorySummary isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 31 : 25}
      />
    </div>
  );
};

export default HOC(InventorySummaryPage);
