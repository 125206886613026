import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  InputAdornment,
  IconButton,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { BsImages } from "react-icons/bs";
import { RiImageAddLine } from "react-icons/ri";
import "react-datepicker/dist/react-datepicker.css";
import { useQuery, useMutation } from "@tanstack/react-query";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment/moment";
import { useFormik } from "formik";

import {
  getDetailInventory,
  getGroups,
  updateProduct,
  getPlatform,
  getCategoryMaster,
  getPrincipal,
} from "../../../../services/InventoryAPI";

import useDebounce from "../../../hooks/useDebounce";
import ErrorPopup from "../../../common/particles.jsx/ErrorPopup";

const FETCH_LIMIT = 10;

const today = moment().format("YYYY-MM-DD");
const toDateApi = (date) => moment(date).format("YYYY-MM-DD");

const EditDetailProductHold = ({ isWarehouse }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [offset, setOffset] = useState(1);
  const [count, setCount] = useState(0);
  const [qtyType, setQtyType] = useState("");
  const [stock, setStock] = useState([]);
  const [total, setTotal] = useState(0);

  const [principalValue, setPrincipalValue] = useState([]);
  const [openPrincipal, setOpenPrincipal] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [optionPrincipal, setOptionPrincipal] = useState([]);
  const [optionCategory, setOptionCategory] = useState([]);
  const [categoryValue, setCategoryValue] = useState([]);
  const [searchPrincipal, setSearchPrincipal] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const debouncePrincipal = useDebounce(searchPrincipal, 500);

  const debounceCategory = useDebounce(searchCategory, 500);

  const { data } = useQuery({
    queryKey: ["detail_product"],
    queryFn: () => getDetailInventory(id, isWarehouse ? isWarehouse : false),
    keepPreviousData: true,
  });

  const { data: platforms } = useQuery({
    queryKey: ["platform"],
    queryFn: () => getPlatform(),
    keepPreviousData: true,
  });

  const { data: categories } = useQuery({
    queryKey: ["category", data],
    queryFn: () => getCategoryMaster(FETCH_LIMIT, offset, searchCategory),
    enabled: !!data,
  });

  const { data: principals, isLoading: isPrincipalLoading } = useQuery({
    queryKey: ["principal", data, debouncePrincipal, openPrincipal],
    queryFn: () => getPrincipal(FETCH_LIMIT, offset, debouncePrincipal),
    enabled: !!data,
  });

  const { mutate } = useMutation({
    mutationFn: updateProduct,
    onSuccess: () =>
      navigate(
        `${
          isWarehouse ? "/central-warehouse" : ""
        }/inventory/produk-aktif/detail-product/${id}`
      ),
  });

  const formik = useFormik({
    initialValues: {
      warehouse: isWarehouse ? isWarehouse : false,
      product_id: parseInt(id, 10),
      nie_number: data && data !== undefined ? data.nie_number : "",
      principal_id: data && data !== undefined ? data.product_principal_id : "",
      category_id:
        data &&
        data !== undefined &&
        data?.category_id?.length > 1 &&
        data?.category_id[0] !== 0
          ? data.product_category_id
          : [],
      label_id: data && data !== undefined ? data.label_product : "",
      product_name: data && data !== undefined ? data.product_name : "",
      product_image: data && data !== undefined ? data.media : [],
      product_description: {
        indication:
          data && data !== undefined ? data.description.indication : "",
        composition:
          data && data !== undefined ? data.description.composition : "",
        dosage: data && data !== undefined ? data.description.dosage : "",
        usage: data && data !== undefined ? data.description.usage : "",
        contraindication:
          data && data !== undefined ? data.description.contraindication : "",
        side_effect:
          data && data !== undefined ? data.description.side_effect : "",
        storage_description:
          data && data !== undefined
            ? data.description.storage_description
            : "",
      },
      require_prescription:
        data && data !== undefined ? data.description.require_prescription : "",
      expired_date: data && data !== undefined ? data.expired_date : "",
      price: {
        platform_price:
          data && data !== undefined && data?.platform_price !== null
            ? data.platform_price.map((p) => ({
                platform_id: p.platform_id,
                price: p.price,
              }))
            : [],
        pharmacy_net_price: data && data !== undefined ? data.hna : 0,
        sell_price: data && data !== undefined ? data.price : 0,
      },
      details_batch: [],
      barcode: data && data !== undefined ? data.barcode : "",
      product_inventory_id:
        data && data !== undefined ? data.product_inventory_id : "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      mutate({ values });
    },
  });

  const { data: groups } = useQuery({
    queryKey: ["groups"],
    queryFn: () => getGroups(),
  });

  const intersection = new Set(
    formik.values.price.platform_price.map(({ platform_id }) => platform_id)
  );
  const result = platforms?.filter(({ id }) => !intersection.has(id));

  const handleAddPlatform = useCallback(() => {
    if (platforms && platforms !== undefined)
      formik.setFieldValue("price.platform_price", [
        ...formik.values.price.platform_price,
        { platform_id: result[0]?.id, price: "" },
      ]);
    else formik.setFieldValue("platform_price", []);
  }, [formik.values.price.platform_price, result]);

  const handleUploadImage = useCallback(
    (e) => {
      try {
        const files = e.currentTarget.files;
        if (files === null) {
          setErrorMessage("Something went wrong when reading the image");
          throw new Error("Something went wrong when reading the image");
        }
        const file = files.item(0);
        if (file === null) {
          setErrorMessage("Something went wrong when reading the image");
          throw new Error("Something went wrong when reading the image");
        }

        const reader = new FileReader();

        reader.onload = (e) => {
          if (!e.target || (e.target && !e.target.result)) {
            setErrorMessage("Something went wrong when reading the image");
            throw new Error("Something went wrong when reading the image");
          }

          const image = new Image();
          image.onload = () => {
            formik.setFieldValue("product_image", [image.src, image.src]);
          };
          image.onerror = () => {
            setErrorMessage("Something went wrong when reading the image");
            throw new Error("Something went wrong when reading the image");
          };
          image.src = e.target.result;
        };
        reader.onerror = () => {
          setErrorMessage("Something went wrong when reading the file");
          throw new Error("Something went wrong when reading the file");
        };

        reader.readAsDataURL(file);
      } catch (e) {
        if (e instanceof Error) {
        }
      }
    },
    [formik.setFieldValue]
  );

  useEffect(() => {
    if (openCategory || openPrincipal) {
      setOptionCategory(categories !== undefined ? categories : []);
      setOptionPrincipal(principals !== undefined ? principals : []);
    }
  }, [categories, principals, openPrincipal, openCategory]);

  useEffect(() => {
    setPrincipalValue({
      id: data && data !== undefined ? data.product_principal_id : "",
      name: data && data !== undefined ? data.product_principal_name : "",
    });
  }, [data]);

  useEffect(() => {
    setCategoryValue(
      data &&
        data !== null &&
        data?.category_id?.length > 1 &&
        data?.category_id[0] !== 0
        ? data.product_category_id.map((id, index) => ({
            id,
            name: data.product_category_name
              ? data.product_category_name[index]
              : "",
          }))
        : []
    );
  }, [debounceCategory, data]);

  return (
    <div className="container mx-auto w-full mt-16 lg:mt-0">
      {data && data !== undefined ? (
        <>
          <div className="flex text-blue-500 gap-2">
            <p
              className="hover:cursor-pointer"
              onClick={() =>
                navigate(
                  `${
                    isWarehouse ? "/central-warehouse" : ""
                  }/inventory/produk-hold`
                )
              }
            >
              Produk Hold
            </p>
            <p>/</p>
            <p>Update Information</p>
          </div>
          <>
            <form onSubmit={formik.handleSubmit}>
              <div className="grid grid-col-1 shadow-md rounded-lg mt-5 p-3">
                <h1 className="font-medium text-[20px] lg:text-[25px]">
                  Detail Produk
                </h1>
                <TextField
                  id="outlined-basic"
                  label="Nomor Izin Edar"
                  value={formik.values.nie_number}
                  onChange={(e) =>
                    formik.setFieldValue("nie_number", e.target.value)
                  }
                  disabled
                  variant="outlined"
                  className="mt-3"
                />

                <div className="input-foto">
                  <div className="bg-blue-500 p-3 text-white font-medium flex mt-4 items-center gap-3 text-[20px] rounded-t-lg">
                    <span>
                      <BsImages />
                    </span>
                    Foto Produk
                  </div>
                  <div className="flex items-center">
                    {data && data.media !== undefined ? (
                      <label
                        htmlFor="drop"
                        className="cursor-pointer flex flex-row items-center justify-center w-full h-full border-2 rounded-b-lg"
                      >
                        <div className="flex justify-center p-4 w-[40vh] h-[40vh] ">
                          <img
                            src={formik.values.product_image[0]}
                            alt="foto produk"
                            className="rounded-lg shadow-md w-full h-full object-cover"
                          />
                        </div>
                        <input
                          id="drop"
                          type="file"
                          hidden
                          accept="accept='image/jpeg,image/png,image/jpg"
                          onChange={(e) => handleUploadImage(e)}
                        />
                      </label>
                    ) : (
                      <label
                        htmlFor="dropzone-file1"
                        className="cursor-pointer flex flex-col items-center justify-center w-full h-64 border-2 rounded-b-lg"
                      >
                        <div className="flex flex-col items-center justify-center">
                          <RiImageAddLine className="text-[90px] text-blue-500" />
                          <p className="font-medium text-l text-blue-400 mt-3">
                            Upload foto product
                          </p>
                          <p className="text-center">
                            <span className="">Ukuran foto maksimal 1 mb</span>
                          </p>
                        </div>
                        <input
                          id="dropzone-file1"
                          type="file"
                          hidden
                          accept="accept='image/jpeg,image/png,image/jpg"
                          onChange={(e) => handleUploadImage(e)}
                        />
                      </label>
                    )}
                  </div>
                </div>
                <TextField
                  id="outlined-basic"
                  label="Nama Obat"
                  name="product_name"
                  variant="outlined"
                  value={formik.values.product_name}
                  onChange={(e) =>
                    formik.setFieldValue("product_name", e.target.value)
                  }
                  className="mt-3"
                />
                <Autocomplete
                  fullWidth
                  multiple
                  filterSelectedOptions
                  sx={{ marginTop: 3 }}
                  open={openCategory}
                  onOpen={() => setOpenCategory(true)}
                  onClose={() => setOpenCategory(false)}
                  disablePortal
                  options={optionCategory}
                  value={categoryValue}
                  onChange={(_, inputValue) => {
                    setCategoryValue(inputValue);
                    formik.setFieldValue(
                      "category_id",
                      inputValue !== null ? inputValue.map((val) => val.id) : []
                    );
                    setOptionCategory([]);
                  }}
                  onInputChange={(_, onInputValue, reason) => {
                    if (reason === "input") {
                      setSearchCategory(onInputValue);
                      setOptionCategory([]);
                    }
                  }}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tambah Kategori"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                <TextField
                  id="outlined-basic"
                  name="indication"
                  value={formik.values.product_description.indication}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "product_description.indication",
                      e.target.value
                    )
                  }
                  label="Indikasi / Kegunaan"
                  variant="outlined"
                  className="mt-3"
                />
                <TextField
                  id="outlined-basic"
                  name="composition"
                  value={formik.values.product_description.composition}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "product_description.composition",
                      e.target.value
                    )
                  }
                  label="Kandungan / Komposisi"
                  variant="outlined"
                  className="mt-3"
                />
                <TextField
                  id="outlined-multiline-static"
                  name="dosage"
                  value={formik.values.product_description.dosage}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "product_description.dosage",
                      e.target.value
                    )
                  }
                  label="Dosis"
                  multiline
                  rows={3}
                  className="mt-3"
                />
                <TextField
                  id="outlined-multiline-static"
                  name="usage"
                  label="Cara Pemakaian"
                  multiline
                  rows={2}
                  value={formik.values.product_description.usage}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "product_description.usage",
                      e.target.value
                    )
                  }
                  className="mt-3"
                />
                <TextField
                  id="outlined-basic"
                  name="contraindication"
                  label="Kontradiksi / Jangan digunakan oleh"
                  value={formik.values.product_description.contraindication}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "product_description.contraindication",
                      e.target.value
                    )
                  }
                  variant="outlined"
                  className="mt-3"
                />
                <TextField
                  id="outlined-basic"
                  label="Efek Samping"
                  name="side_effect"
                  multiline
                  rows={3}
                  variant="outlined"
                  className="mt-3"
                  value={formik.values.product_description.side_effect}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "product_description.side_effect",
                      e.target.value
                    )
                  }
                />
                <TextField
                  id="outlined-basic"
                  label="Cara Penyimpanan"
                  name="storage_description"
                  variant="outlined"
                  className="mt-3"
                  value={formik.values.product_description.storage_description}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "product_description.storage_description",
                      e.target.value
                    )
                  }
                />

                <FormControl fullWidth className="mt-3">
                  <InputLabel id="demo-simple-select-label">
                    Golongan Obat
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    name="label_id"
                    value={formik.values.label_id}
                    label="Golongan Obat"
                    onChange={(e) =>
                      formik.setFieldValue("label_id", e.target.value)
                    }
                  >
                    <MenuItem
                      sx={{ display: "none" }}
                      disabled
                      value=""
                    ></MenuItem>
                    {groups &&
                      groups.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <TextField
                  id="outlined-basic"
                  label="require-prescription"
                  variant="filled"
                  className="mt-3"
                  disabled
                  value={formik.values.require_prescription ? "Ya" : "Tidak"}
                  onChange={(e) =>
                    formik.setFieldValue("require_prescription", e.target.value)
                  }
                />
                <Autocomplete
                  fullWidth
                  sx={{ marginTop: 3 }}
                  loading={isPrincipalLoading}
                  open={openPrincipal}
                  onOpen={() => setOpenPrincipal(true)}
                  onClose={() => setOpenPrincipal(false)}
                  disablePortal
                  options={optionPrincipal}
                  value={principalValue}
                  onChange={(_, inputValue) => {
                    setPrincipalValue(inputValue);
                    formik.setFieldValue("principal_id", inputValue?.id);
                    setOptionPrincipal([]);
                  }}
                  onInputChange={(_, onInputValue, reason) => {
                    if (reason === "input") {
                      setSearchPrincipal(onInputValue);
                      setOptionPrincipal([]);
                    }
                  }}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                  id="combo-box-demo"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Principal"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isPrincipalLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                <div>
                  {formik.values.details_batch.map((b, index) => (
                    <div className="flex justify-evenly mt-4" key={index}>
                      <>
                        <TextField
                          sx={{ width: "40%" }}
                          id="outlined-basic"
                          label="Nomor Batch"
                          variant="outlined"
                          value={b.number_batch}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "details_batch",
                              formik.values.details_batch.map((b1, idx) => {
                                return index === idx
                                  ? { ...b, number_batch: e.target.value }
                                  : b1;
                              })
                            )
                          }
                        />
                      </>
                      <>
                        <TextField
                          sx={{ width: "30%", marginX: 3 }}
                          id="outlined-basic"
                          label="Stock"
                          variant="outlined"
                          value={stock[index]?.stock}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "details_batch",
                              formik.values.details_batch.map((b1, idx) => {
                                return index === idx
                                  ? {
                                      ...b,
                                      stock: Number(e.target.value) * total,
                                    }
                                  : b1;
                              })
                            );
                            setStock(
                              stock.map((s, idx) => {
                                return index === idx
                                  ? { ...s, stock: Number(e.target.value) }
                                  : s;
                              })
                            );
                          }}
                        />
                      </>
                      <>
                        <DesktopDatePicker
                          label="Tanggal Expired"
                          sx={{ width: "100%" }}
                          value={b.expired_date !== "" ? b.expired_date : null}
                          onChange={(newExpiredDate) =>
                            formik.setFieldValue(
                              "details_batch",
                              formik.values.details_batch.map((b1, idx) => {
                                return index === idx
                                  ? {
                                      ...b,
                                      expired_date: toDateApi(newExpiredDate),
                                    }
                                  : b1;
                              })
                            )
                          }
                          renderInput={(params) => {
                            return <TextField {...params} />;
                          }}
                          disablePast={true}
                        />
                      </>
                      <>
                        <button
                          type="button"
                          className="ml-[10px]"
                          onClick={() => {
                            if (formik.values.details_batch.length === 1) {
                              formik.setFieldValue("details_batch", []);
                            } else {
                              formik.setFieldValue("details_batch", [
                                ...formik.values.details_batch.slice(0, index),
                                ...formik.values.details_batch.slice(index + 1),
                              ]);
                            }
                          }}
                        >
                          <DeleteIcon sx={{ color: "black" }} />
                        </button>
                      </>
                    </div>
                  ))}
                  <p
                    className="text-blue-500 hover:underline hover:text-blue-500 cursor-pointer mt-4 text-end"
                    onClick={() => {
                      if (
                        formik.values.details_batch.length === count ||
                        formik.values.details_batch
                          .map((b) => b.stock / total === count)
                          .includes(true)
                      )
                        return;
                      formik.setFieldValue("details_batch", [
                        ...formik.values.details_batch,
                        {
                          stock: "",
                          number_batch: "",
                          expired_date: "",
                          tanggal_input: today,
                        },
                      ]);
                    }}
                  >
                    +Tambah Batch lain
                  </p>
                </div>

                <div className="grid grid-cols-2 justify-between mt-4 items-center">
                  <p className="font-bold">HNA</p>
                  <TextField
                    id="outlined-basic"
                    label="HNA"
                    variant="outlined"
                    fullWidth
                    value={formik.values.price.pharmacy_net_price}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "price.pharmacy_net_price",
                        Number(e.target.value)
                      )
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">Rp.</InputAdornment>
                      ),
                    }}
                  />
                </div>
                {isWarehouse ? null : (
                  <div className="grid grid-cols-2 justify-between mt-4 items-center">
                    <p className="font-bold">Harga Jual</p>
                    <TextField
                      id="outlined-basic"
                      label="Harga Jual"
                      variant="outlined"
                      fullWidth
                      value={formik.values.price.sell_price}
                      onChange={(e) =>
                        formik.setFieldValue(
                          "price.sell_price",
                          Number(e.target.value)
                        )
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">Rp.</InputAdornment>
                        ),
                      }}
                    />
                  </div>
                )}
                <TextField
                  fullWidth
                  label="Barcode"
                  sx={{ marginTop: 3 }}
                  value={formik.values.barcode}
                  onChange={(e) =>
                    formik.setFieldValue("barcode", e.target.value)
                  }
                />
                {isWarehouse ? null : (
                  <div>
                    <p
                      className="text-blue-500 text-end hover:underline hover:text-blue-500 cursor-pointer mt-4"
                      onClick={handleAddPlatform}
                    >
                      +Tambah Platform lain
                    </p>
                    {platforms &&
                    platforms !== undefined &&
                    formik.values.price.platform_price.length > 0 ? (
                      <>
                        {formik.values.price.platform_price.map(
                          (plat, index) => {
                            return (
                              <div
                                className="flex justify-center items-center mt-[20px]"
                                key={plat.platform_id}
                              >
                                <FormControl fullWidth sx={{ marginRight: 1 }}>
                                  <InputLabel>Kategori</InputLabel>
                                  <Select
                                    id="add-category"
                                    value={plat.platform_id}
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        "price.platform_price",
                                        formik.values.price.platform_price.map(
                                          (p1) => {
                                            return p1.platform_id ===
                                              plat.platform_id
                                              ? {
                                                  ...plat,
                                                  platform_id: e.target.value,
                                                }
                                              : p1;
                                          }
                                        )
                                      );
                                    }}
                                  >
                                    {platforms.map((p) => {
                                      return (
                                        <MenuItem
                                          key={p.id}
                                          value={p.id}
                                          disabled={
                                            formik.values.price.platform_price.find(
                                              (f) => f.platform_id === p.id
                                            )
                                              ? true
                                              : false
                                          }
                                          sx={{
                                            display:
                                              formik.values.price.platform_price.find(
                                                (f) => f.platform_id === p.id
                                              )
                                                ? "none"
                                                : null,
                                          }}
                                        >
                                          {p.name}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                                <TextField
                                  id="outlined-basic"
                                  label="Harga Jual"
                                  variant="outlined"
                                  fullWidth
                                  value={plat.price}
                                  onChange={(e) =>
                                    formik.setFieldValue(
                                      "price.platform_price",
                                      formik.values.price.platform_price.map(
                                        (p1) => {
                                          return p1.platform_id ===
                                            plat.platform_id
                                            ? {
                                                ...plat,
                                                price: Number(e.target.value),
                                              }
                                            : p1;
                                        }
                                      )
                                    )
                                  }
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        Rp.
                                      </InputAdornment>
                                    ),
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          edge="end"
                                          onClick={() => {
                                            if (
                                              formik.values.price.platform_price
                                                .length === 1
                                            ) {
                                              formik.setFieldValue(
                                                "price.platform_price",
                                                []
                                              );
                                            } else {
                                              formik.setFieldValue(
                                                "price.platform_price",
                                                [
                                                  ...formik.values.price.platform_price.slice(
                                                    0,
                                                    index
                                                  ),
                                                  ...formik.values.price.platform_price.slice(
                                                    index + 1
                                                  ),
                                                ]
                                              );
                                            }
                                          }}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                            );
                          }
                        )}
                      </>
                    ) : null}
                  </div>
                )}
                <div className="grid grid-cols-2 mt-10 gap-12">
                  <button
                    className="bg-red-700 text-white font-semibold p-3 rounded-lg"
                    onClick={() =>
                      navigate(
                        `${
                          isWarehouse ? "/central-warehouse" : ""
                        }/inventory/produk-hold/detail-product/${id}`
                      )
                    }
                  >
                    Batal
                  </button>
                  <button
                    className="bg-orange-400 text-white font-semibold p-3 rounded-lg disabled:bg-gray-300"
                    disabled={formik.values.category_id.length === 0}
                    type="submit"
                  >
                    Konfirmasi
                  </button>
                </div>
              </div>
            </form>
          </>
        </>
      ) : (
        <p>Tidak Ditemukan!</p>
      )}

      <ErrorPopup
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
    </div>
  );
};

export default EditDetailProductHold;
