import React from "react";
import DetailLogShift from "../../../components/screen/Reporting/LogShift/DetailLogShift";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import HOC from "../../../components/HOC";

const DetailLogShiftPage = () => {
  return (
    <div className="">
      <PageTemplate
        header="Detail Log Shift"
        mainComponent={<DetailLogShift />}
        pageRole={5}
      />
    </div>
  );
};

export default HOC(DetailLogShiftPage);
