import React from "react";
import HOC from "../../components/HOC";
import InvoiceList from "../../components/screen/invoice/InvoiceList";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const InvoicePage = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header={isWarehouse ? "Central Warehouse" : "" + " Invoice List"}
        mainComponent={<InvoiceList isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 42 : 11}
      />
    </div>
  );
};

export default HOC(InvoicePage);
