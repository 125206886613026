import React from "react";
import DetailProductStockOut from "../../../components/screen/inventory/productHabis/DetailProductStockOut";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import HOC from "../../../components/HOC";

const DetailsProductHabisPage = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header="Detail Inventory"
        mainComponent={<DetailProductStockOut isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 27 : 4}
      />
    </div>
  );
};

export default HOC(DetailsProductHabisPage);
