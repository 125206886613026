import React from "react";
import HOC from "../../../components/HOC";
import AddRequestWarehouse from "../../../components/screen/centralWarehouse/request/AddRequestWarehouse";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";

const AddRequestAllocationPage = () => {
  return (
    <div className="">
      <PageTemplate
        header="New Request Allocation"
        mainComponent={<AddRequestWarehouse type={4} />}
        pageRole={34}
      />
    </div>
  );
};

export default HOC(AddRequestAllocationPage);
