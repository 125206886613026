import React from "react";
import HOC from "../../components/HOC";
import OverdueBilling from "../../components/screen/billing/OverdueBilling";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const OverdueBillingPage = () => {
  return (
    <div className="">
      <PageTemplate header="Billing" mainComponent={<OverdueBilling />} />
    </div>
  );
};

export default HOC(OverdueBillingPage);
