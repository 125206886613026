import React from "react";
import HOC from "../../components/HOC";
import InvoiceSettingComponents from "../../components/screen/settings/InvoiceSetting";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const InvoiceSetting = () => {
  return (
    <div className="">
      <PageTemplate
        header="Invoice Setting"
        mainComponent={<InvoiceSettingComponents />}
        pageRole={0}
      />
    </div>
  );
};

export default HOC(InvoiceSetting);
