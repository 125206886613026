import PatientCarePatientList from "../../components/screen/patientCare/patientCarePatient/PatientCarePatientList";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";
import HOC from "../../components/HOC";

const PatientCarePatientDatabasePage = () => {
  return (
    <PageTemplate
      header="Patient Database"
      mainComponent={<PatientCarePatientList />}
      pageRole={20}
    />
  );
};

export default HOC(PatientCarePatientDatabasePage);
