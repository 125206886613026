import React from "react";
import UpdateStockHabis from "../../../components/screen/inventory/productHabis/UpdateStockHabis";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import HOC from "../../../components/HOC";

const UpdateProductHabis = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header="Update Stock dan Harga"
        mainComponent={<UpdateStockHabis isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 27 : 4}
      />
    </div>
  );
};

export default HOC(UpdateProductHabis);
