import React from "react";
import PatientCareDoctorList from "../../components/screen/patientCare/patientCareDoctor/PatientCareDoctorList";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";
import HOC from "../../components/HOC";

const PatientCareDoctorDatabasePage = () => {
  return (
    <PageTemplate
      header="Doctor Database"
      mainComponent={<PatientCareDoctorList />}
      pageRole={21}
    />
  );
};

export default HOC(PatientCareDoctorDatabasePage);
