import React from "react";
import EditPriceConsignmentProduct from "../../../components/screen/inventory/Consignment/EditPriceConsignmentProduct";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import HOC from "../../../components/HOC";

const EditPriceonsignmentProductPage = () => {
  return (
    <div className="">
      <PageTemplate
        header="Update Harga Inventori"
        mainComponent={<EditPriceConsignmentProduct />}
        pageRole={8}
      />
    </div>
  );
};

export default HOC(EditPriceonsignmentProductPage);
