import React from "react";
import StockOpnameRecap from "../../../components/screen/inventory/StockOpname/StockOpnameRecap";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import HOC from "../../../components/HOC";

const StockOpnameRecapPage = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header={"Stock Opname " + (isWarehouse ? "Central Warehouse" : "")}
        mainComponent={<StockOpnameRecap isWarehouse={isWarehouse} />}
        pageRole={23}
      />
    </div>
  );
};

export default HOC(StockOpnameRecapPage);
